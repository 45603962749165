import { useEffect, useState } from "react";
import {Container} from "react-bootstrap";
import styles from "./_styles.module.scss";
import LanguageSwitcher from "../LanguageSwitcher";
import LogoBlock from "../LogoBlock";
import {NavLink} from "react-router-dom";
import {Icon} from "../_ui";

const Header = ({ contacts }) => {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    try {
      setPhone('+38 (044) 538 00 89');
    } catch {}
  }, [contacts]);

  return (
    <header
      className={`${styles["header"]} ${styles["header_account"]}`}
    >
      <div className={styles["app-header_color-stripe"]} />
      <Container
        fluid
        className={`${styles["app-header"]} ${styles["app-header_account"]}`}
      >
        <Container fluid className={styles["app-header-content"]}>
          <div className={styles["app-header-content_left-side"]}>
            <a href="https://avtor.ua">
              <LogoBlock expanded />
            </a>
          </div>
          <div className={styles["app-header-content_right-side"]}>
            <a
              className={styles["app-header-content_right-side_element"]}
              href={`tel:${phone}`}
            >
              <Icon name="phone" />
              <span className={styles["app-header-content_right-hide_element"]}>
                {phone}
              </span>
            </a>
            <LanguageSwitcher />
            <UserButtonBlock />
          </div>
        </Container>
      </Container>
    </header>
  );
};

const UserButtonBlock = () => (
    <NavLink to='/' className={styles["app-header-content_user-button-block"]}>
        <Icon name="user"/>
    </NavLink>
);


export default Header;
