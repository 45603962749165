import {CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_LANGUAGE} from "../types";
import {getLanguage} from "../../utils/functions";

const initialState = {
    loading: false,
    error: null,
    lang: getLanguage()
}

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERRORS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }
        case LOADING_UI: {
            return {
                ...state,
                loading: true
            }
        }
        case SET_LANGUAGE: {
            return {
                ...state,
                lang: action.payload
            }
        }
        default:
            return state;
    }
}