import styles from "./_styles.module.scss";
import {useSelector} from "react-redux";
import {Icon} from "../_ui";

const LogoBlock = ({ noHide = false, expanded = true }) => {
  const lang = useSelector((state) => state.ui.lang);
  return (
    <div
        className={`${styles["app-header-content_logo"]} ${
          noHide ? styles["noHide"] : ""
        }`}
      >
        <Icon name="logoShield" />
        {expanded ? (
          <Icon
            className={styles["app-header-content_logo-name"]}
            name={lang === "en" ? "logoTextEN" : "logoText"}
          />
        ) : null}
    </div>
  );
};

export default LogoBlock;
