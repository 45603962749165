import React from "react";
import styles from "./_styles.module.scss";

export const Form = ({ div = false, className = "", ...rest }) =>
  div ? (
    <div
      className={`${styles.form}${className ? " " + className : ""}`}
      {...rest}
    />
  ) : (
    <form
      className={`${styles.form}${className ? " " + className : ""}`}
      {...rest}
    />
  );

export const FieldBox = ({ className = "", children }) => (
  <div className={`${styles["field-box"]}${className ? " " + className : ""}`}>
    {children}
  </div>
);

export const Title = ({ children = null, required = false }) =>
  children && (
    <span className={styles["field-title"]}>
      {children}
      {required && "*"}
    </span>
  );

export const Hint = ({className= "", children = "" }) =>
  children ? (
    <span className={`${styles["field-hint"]} aui-hint ${className}`}>
      {children.trim()}
    </span>
  ) : null;
