export const LOCALES = {
    English: 'en',
    Ukrainian: 'uk',
    Russian: 'ru',
};

export const browserLanguageInterpreter = [
    "ru",
    "uk",
    "en",
];

export const russianSpeakers = ["be-BY", "bg-BG", "be", "bg"];