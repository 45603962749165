import React from "react";
import PropTypes from "prop-types";
import styles from "./_styles.module.scss";

import {ReactComponent as alert} from "./svg/alert.svg";
import {ReactComponent as eye} from "./svg/eye.svg";
import {ReactComponent as publish} from "./svg/publish.svg";
import {ReactComponent as arrowLeft} from "./svg/arrowLeft.svg";
import {ReactComponent as file} from "./svg/file.svg";
import {ReactComponent as published} from "./svg/published.svg";
import {ReactComponent as arrowRight} from "./svg/arrowRight.svg";
import {ReactComponent as files} from "./svg/files.svg";
import {ReactComponent as remove} from "./svg/remove.svg";
import {ReactComponent as arrowUp} from "./svg/arrowUp.svg";
import {ReactComponent as help} from "./svg/help.svg";
import {ReactComponent as minus} from "./svg/minus.svg";
import {ReactComponent as hidden} from "./svg/hidden.svg";
import {ReactComponent as save} from "./svg/save.svg";
import {ReactComponent as book} from "./svg/book.svg";
import {ReactComponent as i18n} from "./svg/i18n.svg";
import {ReactComponent as search} from "./svg/search.svg";
import {ReactComponent as calendar} from "./svg/calendar.svg";
import {ReactComponent as info} from "./svg/info.svg";
import {ReactComponent as settings} from "./svg/settings.svg";
import {ReactComponent as check} from "./svg/check.svg";
import {ReactComponent as link} from "./svg/link.svg";
import {ReactComponent as show} from "./svg/show.svg";
import {ReactComponent as chevronDown} from "./svg/chevronDown.svg";
import {ReactComponent as logout} from "./svg/logout.svg";
import {ReactComponent as time} from "./svg/time.svg";
import {ReactComponent as chevronLeft} from "./svg/chevronLeft.svg";
import {ReactComponent as menu} from "./svg/menu.svg";
import {ReactComponent as times} from "./svg/times.svg";
import {ReactComponent as chevronRight} from "./svg/chevronRight.svg";
import {ReactComponent as moreH} from "./svg/moreH.svg";
import {ReactComponent as trash} from "./svg/trash.svg";
import {ReactComponent as chevronUp} from "./svg/chevronUp.svg";
import {ReactComponent as moreV} from "./svg/moreV.svg";
import {ReactComponent as update} from "./svg/update.svg";
import {ReactComponent as close} from "./svg/close.svg";
import {ReactComponent as upload} from "./svg/upload.svg";
import {ReactComponent as download} from "./svg/download.svg";
import {ReactComponent as nologo} from "./svg/nologo.svg";
import {ReactComponent as users} from "./svg/users.svg";
import {ReactComponent as edit} from "./svg/edit.svg";
import {ReactComponent as notfound} from "./svg/notfound.svg";
import {ReactComponent as externalLink} from "./svg/externalLink.svg";
import {ReactComponent as plus} from "./svg/plus.svg";
import {ReactComponent as userDelete} from "./svg/userDelete.svg";
import {ReactComponent as userCheck} from "./svg/userCheck.svg";
import {ReactComponent as userAdd} from "./svg/userAdd.svg";
import {ReactComponent as user} from "./svg/user.svg";
import {ReactComponent as unlock} from "./svg/unlock.svg";
import {ReactComponent as lock} from "./svg/lock.svg";
import {ReactComponent as loader} from "./svg/loader.svg";
import {ReactComponent as copy} from "./svg/copy.svg";
import {ReactComponent as resetpass} from "./svg/resetpass.svg";
import {ReactComponent as CSKrequest} from "./svg/CSKrequest.svg";
import {ReactComponent as reissue} from "./svg/reissue.svg";
import {ReactComponent as hsm} from "./svg/hsm.svg";
import {ReactComponent as spiner} from "./svg/spiner.svg";
import {ReactComponent as filter} from "./svg/filter.svg";
import {ReactComponent as apple} from "./svg/apple.svg";
import {ReactComponent as bullet} from "./svg/bullet.svg";
import {ReactComponent as desktop} from "./svg/desktop.svg";
import {ReactComponent as linux} from "./svg/linux.svg";
import {ReactComponent as scancode} from "./svg/scancode.svg";
import {ReactComponent as web} from "./svg/web.svg";
import {ReactComponent as windows} from "./svg/windows.svg";
import {ReactComponent as products} from "./svg/products.svg";
import {ReactComponent as solutions} from "./svg/solutions.svg";
import {ReactComponent as noImage} from "./svg/noImage.svg";
import {ReactComponent as uploadMini} from "./svg/uploadMini.svg";
import {ReactComponent as phone} from "./svg/phone.svg";
import {ReactComponent as phoneFooter} from "./svg/phone_footer.svg";
import {ReactComponent as logoShield} from "./svg/logoShield.svg";
import {ReactComponent as logoText} from "./svg/logoText.svg";
import {ReactComponent as logoTextEN} from "./svg/logoTextEN.svg";
import {ReactComponent as map} from "./svg/map.svg";
import {ReactComponent as mapFooter} from "./svg/map_footer.svg";
import {ReactComponent as mail} from "./svg/mail.svg";
import {ReactComponent as payment} from "./svg/payment.svg";
import {ReactComponent as novaposhta} from "./svg/novaposhta.svg";
import {ReactComponent as copyright} from "./svg/copyright.svg";
import {ReactComponent as facebook} from "./svg/facebook.svg";
import {ReactComponent as email} from "./svg/email.svg";

export const iconsSet = {
  alert,
  eye,
  publish,
  arrowLeft,
  file,
  published,
  arrowRight,
  files,
  remove,
  arrowUp,
  help,
  minus,
  hidden,
  save,
  book,
  i18n,
  search,
  calendar,
  info,
  settings,
  check,
  link,
  show,
  chevronDown,
  logout,
  time,
  chevronLeft,
  menu,
  times,
  chevronRight,
  moreH,
  trash,
  chevronUp,
  moreV,
  update,
  close,
  upload,
  download,
  nologo,
  users,
  edit,
  notfound,
  externalLink,
  plus,
  userDelete,
  userCheck,
  userAdd,
  user,
  unlock,
  lock,
  loader,
  copy,
  resetpass,
  CSKrequest,
  reissue,
  hsm,
  spiner,
  filter,
  apple,
  bullet,
  desktop,
  linux,
  scancode,
  web,
  windows,
  products,
  solutions,
  noImage,
  uploadMini,
  phone,
  logoShield,
  logoText,
  mail,
  payment,
  novaposhta,
  phoneFooter,
  map,
  mapFooter,
  copyright,
  facebook,
  logoTextEN,
  email,
};

const Icon = ({ name, className = "", spin = false, ...props }) => {
  const IconComponent = iconsSet[name] || null;

  return (
    IconComponent && (
      <React.Fragment>
        <IconComponent
          className={`aui-icon ${className} ${spin ? styles.spin : ""}`}
          {...props}
        />
      </React.Fragment>
    )
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  spin: PropTypes.bool,
};

export default Icon;
