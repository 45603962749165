import {LOCALES} from "../utils/constants";

export const uk = {
    [LOCALES.Ukrainian]: {
        auth: {
            title: 'Авторизація',
            form: {
                userTitle: 'Вхід в особистий кабінет',
                adminTitle: 'Вхід на сторінку адміністратора',
                login: 'Логін',
                emptyLogin: 'Введіть логін',
                password: 'Пароль',
                emptyPassword: 'Введіть пароль',
                signInBtn: 'Увійти'
            }
        },
        user: {
            title: 'Особистий кабінет',
            logoutBtn: 'Вийти',
            sentTableTitle: 'Відправлені файли для АВТОР',
            receivedTableTitle: 'Отримані файли від АВТОР',
        },
        admin: {
            main: {
                title: 'Адміністративна сторінка',
                logout: 'Вийти',
                subtitle: {
                    admin: 'Управління користувачами та файлами',
                    superAdmin: 'Управління користувачами та адміністраторами',
                },
                menu: {
                    emailBtn: 'Керувати E-mail розсилкою',
                    newUserBtn: 'Додати користувача',
                    newAdminBtn: 'Додати адміністратора',
                },
                tableSwitcher: {
                    users: 'Користувачі',
                    admins: 'Адміністратори'
                },
                table: {
                    company: {
                        title: 'Компанія',
                        search: 'Пошук по компанії',
                        noCompany: 'Назва компанії не вказана'
                    },
                    user: {
                        title: 'Користувач',
                        search: 'Пошук по користувачу'
                    },
                    admin: {
                        title: 'Адміністратор'
                    },
                    superAdmin: {
                        title: 'Супер Адмін'
                    },
                    email: {
                        title: 'E-mail',
                        search: 'Пошук по E-mail',
                        noEmail: 'E-mail не вказано'
                    },
                    password: {
                        title: 'Пароль'
                    },
                    active: {
                        title: 'Активність користувача',
                        search: {
                            all: 'Всі',
                            active: 'Активні',
                            inactive: 'Неактивні'
                        }
                    },
                    sentFiles: {
                        title: 'Відправлення файлів',
                        search: {
                            all: 'Всі',
                            send: 'Дозволено',
                            noSend: 'Не дозволено'
                        }
                    },
                    date: {
                        title: 'Дата реєстрації'
                    },
                    menu: {
                        search: 'Пошук',
                        files: 'Файли',
                        edit: 'Редагувати',
                        delete: 'Видалити'
                    },
                    deleteModal: {
                        userTitle: 'Видалення користувача',
                        userBody: 'Ви дійсно бажаєте видалити користувача ',
                        adminTitle: 'Видалення адміністратора',
                        adminBody: 'Ви дійсно бажаєте видалити адміністратора ',
                        cancelBtn: 'Скасувати',
                        deleteBtn: 'Видалити',
                    },
                    noUsers: 'Користувачів не знайдено',
                    noAdmins: 'Адміністраторів не знайдено'
                }
            },
            userEdit: {
                addTitle: 'Новий користувач',
                editTitle: 'Редагування користувача',
                form: {
                    company: 'Компанія',
                    name: 'Логін користувача',
                    email: 'E-mail',
                    password: 'Пароль',
                    passwordRepeat: 'Повторіть пароль',
                    passwordChangeBtn: 'Змінити пароль',
                    newPassword: 'Новий пароль',
                    active: 'Вхід дозволено',
                    instFiles: 'Дозволити відправляти файли',
                    quota: 'Квота, Мб',
                    createBtn: 'Створити користувача',
                    saveChangesBtn: 'Зберегти зміни'
                }
            },
            adminEdit: {
                addTitle: 'Новий адміністратор',
                editTitle: 'Редагування адміністратора',
                form: {
                    login: 'Логін адміністратора',
                    password: 'Пароль',
                    passwordRepeat: 'Повторіть пароль',
                    newPassword: 'Новий пароль',
                    super: 'Супер Адмін',
                    createBtn: 'Створити адміністратора'
                }
            },
            userFiles: {
                title: 'Файли користувача',
                sentTableTitle: 'Відправлені файли для користувача ',
                receivedTableTitle: 'Отримані файли від користувача ',
                dragAndDrop: {
                    title: 'Для відправлення перетягніть файл сюди',
                    or: 'або',
                    selectFileBtn: 'Обрати файл',
                    maxSize: 'Максимальний розмір: ',
                    quota: 'Квота: ',
                    loadBtn: 'Завантажити'
                },
                received: 'Отримані файли',
                sent: 'Відправлені файли',
                table: {
                    file: 'Файл',
                    fileFormat: 'Формат файлу: ',
                    emptyFileName: 'Ім\'я файлу не повинно бути пустим',
                    size: 'Розмір',
                    date: 'Дата та час завантаження',
                    downloadsCount: 'Загальна кількість завантажень',
                    description: 'Опис',
                    noDescription: 'Немає опису',
                    deleteBtn: 'Видалити',
                    downloadBtn: 'Завантажити',
                    kbSizeUnit: 'Кб',
                    mbSizeUnit: 'Мб',
                    noSentFiles: 'Немає відправлених файлів',
                    noReceivedFiles: 'Немає отриманих файлів'
                },
                deleteModal: {
                    title: 'Видалення файлів',
                    body: 'Ви дійсно бажаєте видалити файли ',
                    cancel: 'Скасувати',
                    delete: 'Видалити'
                }
            },
            backToTable: 'Назад до таблиці'
        },
        errors: {
            emptyField: 'Поле не повинно бути пустим',
            companyMaxLength: 'Назва компанії повинна бути не більша 100 символів',
            nameMinLength: 'Логін повинен бути не менше 3 символів',
            nameMaxLength: 'Логін повинен бути не більше 100 символів',
            nameException: 'Дозволені лише літери (a-z, A-Z), числа (0-9) та символи ("-","_",".")',
            invalidEmail: 'Не коректний E-mail',
            emailException: 'Дозволені лише літери (a-z, A-Z), числа (0-9) та символи ("@","-","_",".")',
            equalEmail: 'Зверніть увагу, користувач з таким E-mail вже існує',
            passwordMinLength: 'Пароль повинен бути не менший 8 символів',
            passwordMaxLength: 'Пароль повинен бути не більше 100 символів',
            passwordMatch: 'Пароль не співпадає',
            wrongAuth: 'Неправильний логін або пароль',
            noResponse: 'Немає відповіді від сервера',
            sameUserLogin: 'Користувач з таким логіном уже існує',
            sameAdminLogin: 'Адміністратор з таким логіном уже існує',
            quotaException: 'Дозволені лише числа',
            quotaLimit: 'Файл не завантажено, перевищений ліміт квоти',
            quotaMinValue: 'Розмір квоти має бути більше 0',
            maxFileSize: 'Розмір файлу повинен бути не більшим 800Мб'
        }
    }
}