
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import AccountPageHead from "../../components/AccountPageHead";
import UserForm from "../../components/AccountForms/UserForm";

const EditUser = () => {
    const intl = useIntl();

    const isAdmin = useSelector(state => state.admin.user.isAdmin);

    return (
        <>
            {
                isAdmin && (<>
                    <AccountPageHead title={intl.formatMessage({id: 'admin.userEdit.editTitle'})} back/>
                    <UserForm editUser/>
                </>)
            }
        </>
    )
}

export default EditUser;