import styles from './_styles.module.scss';
import SentFiles from "./SentFiles";
import {useDispatch, useSelector} from "react-redux";
import AccountPageHead from "../AccountPageHead";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {requestSelectedUser, setSelectedUser} from "../../redux/actions/adminAction";
import {requestUserFiles, setUserFiles} from "../../redux/actions/filesAction";
import {useRouter} from "../../utils/hooks";
import {AvtorTabItem, AvtorTabs} from "../_ui";

const UserFilesPage = ({ idToken }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const router = useRouter();

    const userId = useSelector(state => state.admin.user.id);
    const userName = useSelector(state => state.admin.user.name);
    const isAdmin = useSelector(state => state.admin.user.isAdmin);
    const isSuperUser = useSelector(state => state.admin.user.isSuper);
    const currentUser = useSelector(state => state.admin.selectedUser);
    const pageSize = useSelector(state => state.files.pageSize);
    const currentPage = useSelector(state => state.files.currentPage);

    const [selectedTable, setSelectedTable] = useState(isAdmin ? 'sent' : 'received');

    useEffect(() => {
        if(idToken) {
            isAdmin ? dispatch(requestSelectedUser('user', router.query.uid))
                : dispatch(requestUserFiles(false, selectedTable, pageSize, currentPage));
            return () => {
                dispatch(setSelectedUser(null))
                dispatch(setUserFiles([]));
            }
        }
    }, [])

    return (
        <>
            {isAdmin ?
                <AccountPageHead back files/>
                :
                <AccountPageHead title={intl.formatMessage({id: 'user.title'})} userName={userName} userPage/>}
            {currentUser?.InstFiles || (!isAdmin && isSuperUser) ? <AvtorTabs className={styles.table_switcher}>
                {isAdmin ? <>
                    <AvtorTabItem active={selectedTable === 'sent'}
                                  onClick={() => {
                                      if(selectedTable !== 'sent') {
                                          setSelectedTable('sent');
                                          dispatch(setUserFiles([]));
                                          dispatch(requestUserFiles(isAdmin, 'sent', pageSize, 1, currentUser.ID));
                                      }
                                  }}
                    >
                        {intl.formatMessage({id: 'admin.userFiles.sent'})}
                    </AvtorTabItem>
                    <AvtorTabItem active={selectedTable === 'received'}
                                  onClick={() => {
                                      if(selectedTable !== 'received') {
                                          setSelectedTable('received');
                                          dispatch(setUserFiles([]));
                                          dispatch(requestUserFiles(isAdmin, 'received', pageSize, 1, currentUser.ID));
                                      }
                                  }}
                    >
                        {intl.formatMessage({id: 'admin.userFiles.received'})}
                    </AvtorTabItem>
                </> : <>
                    <AvtorTabItem active={selectedTable === 'received'}
                                  onClick={() => {
                                      if(selectedTable !== 'received') {
                                          setSelectedTable('received');
                                          dispatch(setUserFiles([]));
                                          dispatch(requestUserFiles(isAdmin, 'received', pageSize, 1, userId));
                                      }
                                  }}
                    >
                        {intl.formatMessage({id: 'admin.userFiles.received'})}
                    </AvtorTabItem>
                    <AvtorTabItem active={selectedTable === 'sent'}
                                  onClick={() => {
                                      if(selectedTable !== 'sent') {
                                          setSelectedTable('sent');
                                          dispatch(setUserFiles([]));
                                          dispatch(requestUserFiles(isAdmin, 'sent', pageSize, 1, userId));
                                      }
                                  }}
                    >
                        {intl.formatMessage({id: 'admin.userFiles.sent'})}
                    </AvtorTabItem>
                </>}
            </AvtorTabs> : null}
            <div className={styles.files_content}>
                {isAdmin ? currentUser && <SentFiles isAdmin={isAdmin} table={selectedTable} currentUser={currentUser} userId={currentUser.ID}/>
                    : <SentFiles isAdmin={isAdmin} table={selectedTable} currentUser={currentUser} userId={userId}/>}
            </div>
        </>
    )
}

export default UserFilesPage;

