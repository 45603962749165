import { Switch, Route } from 'react-router-dom';
import AdminPage from "../../components/AdminPage";
import AddUser from "../add_user";
import AddAdmin from "../add_admin";
import EditUser from "../edit_user";
import EditAdmin from "../edit_admin";
import UserFilesPage from "../../components/UserFilesPage";
import LoginForm from "../../components/AccountForms/LoginForm";

const Admin = (props) => {
    const idToken = localStorage.getItem('idToken');

    return (
        <Switch>
            <Route exact path={props.match.path + "/"} render={() => <AdminPage idToken={idToken}/>}/>
            <Route path={props.match.path + "/add_user"} render={() => <AddUser />}/>
            <Route path={props.match.path + "/add_admin"} component={AddAdmin}/>
            <Route path={props.match.path + "/edit_user/:uid"} component={EditUser}/>
            <Route path={props.match.path + "/edit_admin/:aid"} component={EditAdmin}/>
            <Route path={props.match.path + "/files/:uid"} render={() => <UserFilesPage idToken={idToken}/>}/>
            <Route exact path={props.match.path + "/login"} render={() => <LoginForm isAdmin={true}/>}/>
        </Switch>
    )
}

export default Admin;