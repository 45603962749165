import Header from "../Header";
import styles from "./_styles.module.scss"

const Layout = ({children}) => {
    return <div className="app-layout">
        <Header/>
        <div className={`app-layout_content ${styles["app-layout_content_account"]}`}>{children}</div>
    </div>
};

export default Layout;
