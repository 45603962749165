import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import AccountPageHead from "../../components/AccountPageHead";
import UserForm from "../../components/AccountForms/UserForm";

const AddUser = () => {
    const isAdmin = useSelector(state => state.admin.user.isAdmin);
    const intl = useIntl();

    return (
        <>
            {
                isAdmin && (<>
                    <AccountPageHead title={intl.formatMessage({id: 'admin.userEdit.addTitle'})} back/>
                    <UserForm newUser />
                </>)
            }
        </>
    )
}

export default AddUser;