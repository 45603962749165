import styles from "./_styles.module.scss";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  checkEmail,
  createNewUser,
  requestSelectedUser, setEmailCheck, setSelectedUser,
  updateUser
} from "../../redux/actions/adminAction";
import {useIntl} from "react-intl";
import {useRouter} from "../../utils/hooks";
import {Field, Form} from "react-final-form";
import {
  composeValidators,
  exception,
  isNumber,
  isValidEmail,
  maxLength,
  minLength, minValue,
  required
} from '../../utils/validators';
import HR from "../_ui/HR";
import {Button, TextField, Checkbox, Icon} from "../_ui";

const Condition = ({when, is, children}) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => (value === is ? children : null)}
  </Field>
)

const UserForm = ({newUser, editUser, newAdmin, editAdmin}) => {
  const {push, query} = useRouter();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (!newUser && !newAdmin) {
      editAdmin ?
        dispatch(requestSelectedUser('admin', query.aid))
        :
        dispatch(requestSelectedUser('user', query.uid));
    }
  }, [])

  const [newPasswordField, setNewPasswordField] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [validEmailValue, setValidEmailValue] = useState('')

  const selectedUser = useSelector(state => state.admin.selectedUser);
  const pageSize = useSelector(state => state.admin.pageSize);
  const responseError = useSelector(state => state.ui.error);
  const isLoading = useSelector(state => state.ui.loading);
  const emailCheck = useSelector(state => state.admin.emailCheck);

  const submitUser = (e) => {
    if (newUser) {
      const newUserData = {
        Name: e.name,
        Email: e.email,
        CompanyName: e.company,
        Password: e.password,
        Active: e.isActive,
        InstFiles: e.isInstFiles
      }
      if (e.isInstFiles) {
        newUserData.MaxQuota = e.quota * 1024 * 1024;
      }

      dispatch(createNewUser(push, 'user', newUserData, pageSize));
    } else if (newAdmin) {
      const newAdminData = {
        Name: e.name,
        Password: e.password,
        Status: e.isSuper
      }

      dispatch(createNewUser(push, 'admin', newAdminData, pageSize));
    } else {
      let updatedUserData;

      editAdmin ? updatedUserData = {
        Id: parseInt(query.aid),
        Name: e.name,
        Status: e.isSuper
      } : updatedUserData = {
        Id: parseInt(query.uid),
        Name: e.name,
        Email: e.email,
        CompanyName: e.company,
        Active: e.isActive,
        InstFiles: e.isInstFiles
      }

      if (e.newPassword) {
        updatedUserData.Password = e.newPassword;
      }
      if (e.isInstFiles) {
        updatedUserData.MaxQuota = e.quota * 1024 * 1024;
      }

      dispatch(updateUser(push, editAdmin ? 'admin' : 'user', updatedUserData));
    }
  }

  const validate = (values) => {
    const errors = {}

    if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = 'passwordRepeat';
    }
    if (values.newPassword !== values.newPasswordRepeat) {
      errors.newPasswordRepeat = 'newPasswordRepeat';
    }

    return errors;
  }

  useEffect(() => {
    if (emailValid) {
      newUser ? dispatch(checkEmail(validEmailValue)) : dispatch(checkEmail(validEmailValue, parseInt(query.uid)))
    } else {
      dispatch(setEmailCheck(false));
    }
  }, [validEmailValue]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedUser(null))
    }
  }, [])

  return (
    <div className={styles.form_wrapper}>
      {isLoading ? <Icon name="spiner" spin/> :
        <div className={`${styles.form} ${styles.form_admin}`}>
          <Form onSubmit={submitUser}
                initialValues={!newUser && selectedUser && ({
                  company: selectedUser.CompanyName,
                  name: selectedUser.Name,
                  email: selectedUser.Email,
                  isActive: selectedUser.Active,
                  isInstFiles: selectedUser.InstFiles,
                  isSuper: selectedUser.Status,
                  quota: selectedUser.MaxQuota / 1024 / 1024
                })}
                validate={validate}
                autoComplete="off"
                render={({handleSubmit, pristine, submitting, valid}) => (
                  <form onSubmit={handleSubmit}
                        autoComplete="off"
                  >
                    {!newAdmin && !editAdmin && <Field name="company"
                                                       validate={maxLength(100)}
                                                       render={({input, meta}) => (
                                                         <div className={styles.form_field}>
                                                           <TextField name="company"
                                                                      title={intl.formatMessage({id: 'admin.userEdit.form.company'})}
                                                                      autoComplete="new-password"
                                                                      {...input}
                                                           />
                                                           {meta.touched && meta.error && meta.error === 'maxLength' &&
                                                             <span className={styles.error}>
                                                                                {intl.formatMessage({id: 'errors.companyMaxLength'})}
                                                                             </span>}
                                                         </div>
                                                       )}
                    />}
                    <Field name="name"
                           validate={(newAdmin || editAdmin) ? composeValidators(required, minLength(3), maxLength(100), exception(`йцукенгшщзхъфывапролдіжэячсымитьбёю!#№$%^&*()+,/${'"'}'|<>:;[]{}=\`~?\\ @`)) : composeValidators(required, exception(`йцукенгшщзхъфывапролдіжэячсымитьбёю!#№$%^&*()+,/${'"'}'|<>:;[]{}=\`~?\\ @`), minLength(3), maxLength(100))}
                           render={({input, meta}) => (
                             <div className={styles.form_field}>
                               <TextField name="name"
                                          title={!newAdmin && !editAdmin ?
                                            <div>
                                              {intl.formatMessage({id: 'admin.userEdit.form.name'})}
                                              <span style={{color: "#ce0014"}}>*</span>
                                            </div>
                                            :
                                            <div>
                                              {intl.formatMessage({id: 'admin.adminEdit.form.login'})}
                                              <span style={{color: "#ce0014"}}>*</span>
                                            </div>}
                                          className={meta.touched && meta.error && 'requiring'}
                                          autoComplete="new-password"
                                          {...input}
                               />
                               {meta.touched && meta.error && meta.error === 'required' &&
                                 <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.emptyField'})}
                                             </span>}
                               {meta.touched && meta.error && meta.error === 'minLength' &&
                                 <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.nameMinLength'})}
                                              </span>}
                               {meta.touched && meta.error && meta.error === 'maxLength' &&
                                 <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.nameMaxLength'})}
                                              </span>}
                               {meta.error && meta.error === 'exception' &&
                                 <span className={styles.error} style={{fontSize: '14px'}}>
                                                 {intl.formatMessage({id: 'errors.nameException'})}
                                              </span>}
                             </div>
                           )}
                    />
                    {!newAdmin && !editAdmin &&
                      <Field name="email"
                             autoComplete="off"
                             validate={composeValidators(exception(`йцукенгшщзхъфывапролдіжэячсымитьбёю!#№$%^&*()+,/${'"'}'|<>:;[]{}=\`~?\\ `), isValidEmail)}
                             render={({input, meta}) => {
                               setValidEmailValue(input.value);
                               setEmailValid(input.value !== '' && meta.valid);
                               console.log(meta.error);
                               return (
                                 <div
                                   className={`${styles.form_field} ${!newUser && styles.form_field_mb}`}>
                                   <TextField name="email"
                                              title="E-mail"
                                              onChange={e => console.log(e.current)}
                                              className={meta.touched && meta.error && 'requiring'}
                                              autoComplete="new-password"
                                              {...input}
                                   />
                                   {meta.touched && meta.error && meta.error === 'email' &&
                                     <span className={styles.error}>
                                                     {intl.formatMessage({id: 'errors.invalidEmail'})}
                                                 </span>}
                                   {meta.error && meta.error === 'exception' &&
                                     <span className={styles.error} style={{fontSize: '14px'}}>
                                                     {intl.formatMessage({id: 'errors.emailException'})}
                                                 </span>}
                                   {emailCheck && <span className={styles.error}>
                                                     {intl.formatMessage({id: 'errors.equalEmail'})}
                                                 </span>}
                                 </div>
                               )
                             }}
                      />}
                    {(!newUser && !newAdmin) && (<>
                      {newPasswordField ? (<>
                        <HR className={styles.form_hr}/>
                        <Field name="newPassword"
                               validate={composeValidators(required, minLength(8), maxLength(100))}
                               render={({input, meta}) => (
                                 <div className={styles.form_field}>
                                   <TextField name="newPassword"
                                              title={<div>
                                                {intl.formatMessage({id: 'admin.userEdit.form.newPassword'})}
                                                <span style={{color: "#ce0014"}}>*</span>
                                              </div>}
                                              type="password"
                                              exceptions=' '
                                              className={`${styles.password_field} ${meta.touched && meta.error && 'requiring'}`}
                                              {...input}
                                   />
                                   {meta.touched && meta.error && meta.error === 'required' &&
                                     <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.emptyField'})}
                                             </span>}
                                   {meta.error === 'minLength' && <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMinLength'})}
                                              </span>}
                                   {meta.error === 'maxLength' && <span classNaame={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMaxLength'})}
                                              </span>}
                                 </div>
                               )}
                        />
                        <Field name="newPasswordRepeat"
                               validate={composeValidators(required)}
                               render={({input, meta}) => (
                                 <div className={styles.form_field}>
                                   <TextField name="newPasswordRepeat"
                                              title={<div>
                                                {intl.formatMessage({id: 'admin.userEdit.form.passwordRepeat'})}
                                                <span style={{color: "#ce0014"}}>*</span>
                                              </div>}
                                              type="password"
                                              exceptions=' '
                                              className={`${styles.password_field} ${meta.touched && meta.error && 'requiring'}`}
                                              {...input}
                                   />
                                   {meta.touched && meta.error && meta.error === 'required' ?
                                     <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.emptyField'})}
                                             </span> : meta.error === 'newPasswordRepeat' ?
                                       <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMatch'})}
                                             </span> : null}
                                 </div>
                               )}
                        />
                        <HR className={styles.form_hr}/>
                      </>) : (<>
                        <HR className={styles.form_hr}/>
                        <Button secondary className={`${styles.form_btn} ${styles.form_btn_password}`}
                                onClick={() => setNewPasswordField(true)}
                        >
                          {intl.formatMessage({id: 'admin.userEdit.form.passwordChangeBtn'})}
                        </Button>
                        <HR className={styles.form_hr}/>
                      </>)}
                    </>)}
                    {(newUser || newAdmin) && (<>
                      <Field name="password"
                             defaultValue={''}
                             validate={composeValidators(required, minLength(8), maxLength(100))}
                             render={({input, meta}) => (
                               <div className={styles.form_field}>
                                 <TextField name="password"
                                            title={<div>
                                              {intl.formatMessage({id: 'admin.userEdit.form.password'})}
                                              <span style={{color: "#ce0014"}}>*</span>
                                            </div>}
                                            type="password"
                                            autoComplete="new-password"
                                            exceptions=' '
                                            className={`${styles.password_field} ${meta.touched && meta.error && 'requiring'}`}
                                            {...input}
                                 />
                                 {meta.touched && meta.error && meta.error === 'required' &&
                                   <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.emptyField'})}
                                             </span>}
                                 {meta.error === 'minLength' &&
                                   <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMinLength'})}
                                              </span>}
                                 {meta.error === 'maxLength' &&
                                   <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMaxLength'})}
                                              </span>}
                               </div>
                             )}
                      />
                      <Field name="passwordRepeat"
                             validate={composeValidators(required)}
                             render={({input, meta}) => (
                               <div className={styles.form_field}>
                                 <TextField name="passwordRepeat"
                                            title={<div>
                                              {intl.formatMessage({id: 'admin.userEdit.form.passwordRepeat'})}
                                              <span style={{color: "#ce0014"}}>*</span>
                                            </div>}
                                            type="password"
                                            exceptions=' '
                                            className={`${styles.password_field} ${meta.touched && meta.error && 'requiring'}`}
                                            {...input}
                                 />
                                 {meta.touched && meta.error && meta.error === 'required' ?
                                   <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.emptyField'})}
                                             </span> : meta.error === 'passwordRepeat' ? <span className={styles.error}>
                                                 {intl.formatMessage({id: 'errors.passwordMatch'})}
                                             </span> : null}
                               </div>
                             )}
                      /></>)}
                    {(newUser || editUser) && <Field
                      name="isActive"
                      type="checkbox"
                      defaultValue={newUser && true}
                      render={({input, meta}) => <div className={styles.form_checkbox}><
                        Checkbox {...input}
                                 title={intl.formatMessage({id: 'admin.userEdit.form.active'})}/>
                      </div>}
                    />}
                    {(newUser || editUser) && <>
                      <Field
                        name="isInstFiles"
                        type="checkbox"
                        defaultValue={newUser && false}
                        render={({input, meta}) => <div className={styles.form_checkbox}><
                          Checkbox {...input}
                                   title={intl.formatMessage({id: 'admin.userEdit.form.instFiles'})}/>
                        </div>}
                      />
                      <Condition when="isInstFiles" is={true}>
                        <Field name="quota"
                               validate={composeValidators(required, isNumber, minValue(1))}
                               render={({input, meta}) => (
                                 <div className={styles.form_field}>
                                   <TextField name="quota"
                                              title={<div>
                                                {intl.formatMessage({id: 'admin.userEdit.form.quota'})}
                                                <span style={{color: "#ce0014"}}>*</span>
                                              </div>}
                                              exceptions=' '
                                              className={`${styles.password_field} ${meta.touched && meta.error && 'requiring'}`}
                                              {...input}
                                   />
                                   {meta.touched && meta.error && meta.error === 'required' ?
                                     <span className={styles.error}>
                                                                {intl.formatMessage({id: 'errors.emptyField'})}
                                                             </span> : meta.error === 'NaN' ?
                                       <span className={styles.error}>
                                                                 {intl.formatMessage({id: 'errors.quotaException'})}
                                                             </span> : meta.error === 'minValue' &&
                                       <span className={styles.error}>
                                                                 {intl.formatMessage({id: 'errors.quotaMinValue'})}
                                                             </span>}
                                 </div>
                               )}
                        />
                      </Condition>
                    </>}
                    {(newAdmin || editAdmin) && <Field
                      name="isSuper"
                      type="checkbox"
                      defaultValue={newUser && false}
                      render={({input, meta}) => <div className={styles.form_checkbox}><
                        Checkbox {...input}
                                 title={intl.formatMessage({id: 'admin.adminEdit.form.super'})}/>
                      </div>}
                    />}
                    {responseError && <span className={`${styles.error} ${styles.error_mt}`}>
                                  {intl.formatMessage({id: `errors.${responseError}`})}
                              </span>}
                    {newUser || newAdmin ? (<>
                      <Button type="submit" className={styles.form_btn}
                              disabled={submitting || pristine || !valid}>
                        {newAdmin ? intl.formatMessage({id: 'admin.adminEdit.form.createBtn'})
                          :
                          intl.formatMessage({id: 'admin.userEdit.form.createBtn'})}
                      </Button>
                    </>) : (<>
                      <Button type="submit" className={styles.form_btn}
                              disabled={submitting || pristine || !valid}
                      >
                        {intl.formatMessage({id: 'admin.userEdit.form.saveChangesBtn'})}
                      </Button>
                    </>)}
                  </form>
                )}
          />
        </div>}
    </div>
  )
}

export default UserForm;