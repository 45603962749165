import {applyMiddleware, combineReducers, createStore} from "redux";
import {adminReducer} from "./reducers/adminReducer";
import {uiReducer} from "./reducers/uiReducer";
import thunkMiddleware from "redux-thunk";
import {filesReducer} from "./reducers/filesReducer";
import {composeWithDevTools} from "redux-devtools-extension";

const reducers = combineReducers({
    admin: adminReducer,
    files: filesReducer,
    ui: uiReducer
})

const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;