import {
    SET_CURRENT_USER_FILES,
    SET_FILES_COUNT,
    SET_FILES_CURRENT_PAGE,
} from "../types";
import {clearErrors, isLoading, setError} from "./uiAction";
import {adminApi, userApi} from "../../utils/api";
import fileDownload from "js-file-download";

export const setUserFiles = (payload) => ({type: SET_CURRENT_USER_FILES, payload});
export const setCurrentPage = (payload) => ({type: SET_FILES_CURRENT_PAGE, payload});
export const setFilesCount = (payload) => ({type: SET_FILES_COUNT, payload});

export const requestUserFiles = (isAdmin, table, limit, pageNumber, userId) => (dispatch) => {
    dispatch(isLoading());
    dispatch(setCurrentPage(pageNumber));

    const requestedApi = (isAdmin ? adminApi : userApi);

    requestedApi.get(`/files${table === 'sent' ? (!isAdmin ? '/userpost' : '') : ('')}?${isAdmin ? (table === 'sent' ? ('UserId=' + userId + '&') : ('PostByUser=' + userId + '&')) : ('')}Limit=${limit}&Offset=${(pageNumber - 1) * limit}`)
        .then(response => {
            setTimeout(() => {
                dispatch(clearErrors());
                dispatch(setUserFiles(response.data));
            }, 200)
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('Немає віповіді від сервера'))
            }
        })
    requestedApi.get(`/files/count${isAdmin ? (table === 'sent' ? ('?UserId=' + userId) : ('?PostByUser=' + userId)) : (table === 'sent' ? ('?PostByUser=' + userId) : '')}`)
        .then(response => {
            dispatch(setFilesCount(response.data));
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('Немає віповіді від сервера'))
            }
        })
}

export const sendFile = (isAdmin, file, limit, pageNumber, userId) => (dispatch) => {
    dispatch(isLoading());
    const requestedApi = (isAdmin ? adminApi : userApi);
    requestedApi.post(`/files${isAdmin ? ('?UserId'+userId) : ''}`, file, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
        .then((res) => {
            dispatch(requestUserFiles(isAdmin, 'sent', limit, pageNumber, userId));
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data === 'limit quota' && 'quotaLimit'));
            } else if (error.request) {
                dispatch(setError('Немає віповіді від сервера'))
            }
        })
}

export const deleteFiles = (isAdmin, selectedFiles, limit, pageNumber, userId) => async (dispatch) => {
    dispatch(isLoading());
    const requestedApi = (isAdmin ? adminApi : userApi);
    await Promise.all(
        selectedFiles.map(async file => {
            await requestedApi.delete(`/files/:${file.id}`)
                .then(res => console.log(res));
        })
    )
        .then(() => {
            dispatch(requestUserFiles(isAdmin, 'sent', limit, pageNumber, userId))
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('Немає віповіді від сервера'))
            }
        })
}

export const loadFile = (isAdmin, fileId, fileName) => (dispatch) => {
    dispatch(isLoading());
    const requestedApi = (isAdmin ? adminApi : userApi);
    requestedApi.get(`/files/:${fileId}`, {responseType: 'blob'})
        .then(res => {
            fileDownload(res.data, fileName);
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('Немає віповіді від сервера'))
            }
        })
}