export const SET_USERS = 'SET_USERS';
export const SET_USERS_COUNT = 'SET_USERS_COUNT';
export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_SELECTED_USER_PASSWORD = 'SET_SELECTED_USER_PASSWORD';
export const SET_SELECTED_USER_PASSWORD_LOADING = 'SET_SELECTED_USER_PASSWORD_LOADING';
export const SET_EMAIL_CHECK = 'SET_EMAIL_CHECK';

export const SET_CURRENT_USER_FILES = 'SET_CURRENT_USER_FILES';
export const SET_FILES_CURRENT_PAGE = 'SET_FILES_CURRENT_PAGE';
export const SET_FILES_COUNT = 'SET_FILES_COUNT';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const SET_LANGUAGE = 'SET_LANGUAGE';