import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./_styles.module.scss";

const CheckBox = ({
  title = "",
  className = "",
  count = null,
  onChange,
  disabled = false,
  ...props
}) => {
  const [checked, setChecked] = useState(
    props.checked || props.defaultChecked || false
  );

  useEffect(() => {
    setChecked(props.checked || props.defaultChecked);
  }, [props.checked, props.defaultChecked]);

  const onChangeAction = (e) => {
    setChecked(e.target.checked);
    if (typeof onChange === "function") {
      onChange(e);
    }
  };

  return (
    <React.Fragment>
      <label
        className={`${styles["checkbox-label"]} ${
          disabled ? styles.disabled : ""
        }`}
      >
        <label
          className={`${styles.checkbox} ${className} ${
            checked ? styles.checked : ""
          }`}
          onClick={() => {
            setChecked((prev) => !prev);
          }}
        >
          {count ? (
            <span className={styles.count}>
              {count > 0 ? (count < 100 ? count : "+") : ""}
            </span>
          ) : (
            <Mark />
          )}
          <input
            type="checkbox"
            disabled={disabled}
            onChange={onChangeAction}
            {...props}
          />
        </label>
        {title ? <span className={styles.title}>{title}</span> : null}
      </label>
      <br className={styles.clear} />
    </React.Fragment>
  );
};

CheckBox.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

const Mark = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13.6L9.4 16L17.4 8"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBox;
