import React from "react";
import PropTypes from "prop-types";
import styles from "./_styles.module.scss";
import {Icon} from "../index";

/**
 * @name Button
 * @description Print button with external handler
 */

const Button = ({
  to = "",
  newTab = false,
  type = "button",
  className = "",
  empty = false,
  secondary = false,
  round = false,
  children = null,
  icon = null,
  iconColor = null,
  iconSpin = false,
  ...rest
}) => {
  const computedClassName = `${styles.button} ${className} ${!children ? styles.mini : ""} ${empty ? styles.empty : ""} ${
    secondary ? styles.secondary : ""
  }
  ${round ? styles.round : ""}
  ${!empty && !secondary ? styles.default : ""}
  ${!!rest && rest.disabled ? styles.disabled : ""}`;

  return to ? (
    <a className={computedClassName} href={rest.disabled ? "" : to} target={newTab ? "__blank" : ""} {...rest}>
      <ButtonInside children={children} icon={icon} iconColor={iconColor} iconSpin={iconSpin} />
    </a>
  ) : (
    <button className={computedClassName} type={type} {...rest}>
      <ButtonInside children={children} icon={icon} iconColor={iconColor} iconSpin={iconSpin} />
    </button>
  );
};

Button.propTypes = {
  to: PropTypes.string, // if there is 'to' prop - wrap button with link
  rest: PropTypes.any,
};

const ButtonInside = ({ children = null, icon = null, iconColor, iconSpin = false }) => (
  <React.Fragment>
    {icon && <Icon name={icon} color={iconColor} spin={iconSpin} />}
    {children}
  </React.Fragment>
);

ButtonInside.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconcolor: PropTypes.string,
  empty: PropTypes.bool,
  secondary: PropTypes.bool,
  rest: PropTypes.any,
};

export default Button;
