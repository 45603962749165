import {browserLanguageInterpreter, russianSpeakers} from "./constants";

function setCurrentLanguage(lang) {
    localStorage.setItem('language', lang);
    return lang;
}

export const getLanguage = () => {
    const lang = localStorage.getItem("language");

    if (lang) {
        return setCurrentLanguage(lang);
    } else {
        const navLang = navigator.language.split("-")[0].toLowerCase();
        if (browserLanguageInterpreter.includes(navLang)) {
            console.log('if browser', setCurrentLanguage(navLang));
            return setCurrentLanguage(navLang);
        } else {
            if (russianSpeakers.includes(navLang)) {
                console.log('if russianspeakers', setCurrentLanguage('ru'));
                return setCurrentLanguage("ru");
            } else {
                console.log('if engspeakers', setCurrentLanguage('en'));
                return setCurrentLanguage("en");
            }
        }
    }
    // function setLanguage(lang) {
    //     localStorage.setItem('lang', lang);
    //     return LOCALES[lang];
    // }
    // const urlLang = new URLSearchParams(window.location.search).get('Lang');
    // if (urlLang && browserLanguageInterpreter[urlLang]) {
    //     return setLanguage(browserLanguageInterpreter[urlLang]);
    // }
    // if (localStorage.getItem('lang')) {
    //     return LOCALES[localStorage.getItem('lang')];
    // }
    // const navLang = (navigator.language || navigator.userLanguage)
    //     .split('-')[0]
    //     .toLowerCase();
    // if (Object.keys(browserLanguageInterpreter).includes(navLang)) {
    //     return setLanguage(browserLanguageInterpreter[navLang]);
    // }
    // if (russianSpeakers.includes(navLang)) {
    //     return setLanguage('ru');
    // }
    // return setLanguage('en');
};

export const flattenMessages = (nestedMessages, prefix = "") => {
    if (nestedMessages === null) {
        return {};
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            Object.assign(messages, { [prefixedKey]: value });
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};

export const bytesToMegaBytes = bytes => (bytes / (1024*1024)).toFixed(2);