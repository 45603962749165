import './App.css';
import {IntlProvider} from "react-intl";
import { Switch, Route } from 'react-router-dom';
import {flattenMessages} from "./utils/functions";
import {useSelector} from "react-redux";
import messages from "./i18n";
import Layout from "./components/Layout";
import UserFilesPage from "./components/UserFilesPage";
import LoginForm from "./components/AccountForms/LoginForm";
import {useEffect} from "react";
import store from "./redux/store";
import {adminApi, userApi} from "./utils/api";
import {
    setAuthenticated,
    setUserData,
} from "./redux/actions/adminAction";
import {useRouter} from "./utils/hooks";
import jwtDecode from "jwt-decode";
import Admin from "./pages/admin";
import dayjs from "dayjs";

const token = localStorage.getItem('idToken');
const userName = localStorage.getItem('userName');
const userStatus = localStorage.getItem('userStatus');
const userId = localStorage.getItem('userId') || undefined;
if(token) {
    const decodedToken = jwtDecode(token);
    if(decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('idToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userStatus');
        store.dispatch(setAuthenticated(false));
        store.dispatch(setUserData({
            id: undefined,
            name: undefined,
            isAdmin: false,
            isSuper: false
        }));
        delete adminApi.defaults.headers.common['Authorization'];
        delete userApi.defaults.headers.common['Authorization'];
        window.location.href = '/login';
    } else {
        store.dispatch(setAuthenticated(true));
        store.dispatch(setUserData({
            id: userId,
            name: userName,
            isAdmin: userStatus.includes('admin'),
            isSuper: userStatus.includes('super')
        }))
        if(userStatus.includes('admin')) {
            adminApi.defaults.headers.common['Authorization'] = token;
        } else {
            userApi.defaults.headers.common['Authorization'] = token;
        }
    }
}
function App() {
    const lang = useSelector((state) => state.ui.lang);
    const isAdmin = useSelector((state) => state.admin.user.isAdmin);
    const { pathname, push } = useRouter();
    const idToken = localStorage.getItem('idToken');

    useEffect(() => {
        if(!idToken) {
            if(pathname.startsWith('/admin')) {
                push('/admin/login');
            } else {
                push('/login');
            }
        } else {
            if(!isAdmin && (pathname.startsWith('/admin') || pathname.startsWith('/login'))) {
                push('/');
            } else if(isAdmin && (!pathname.startsWith('/admin')) || pathname.includes('login')) {
                push('/admin');
            }
        }
    }, [pathname, idToken, isAdmin, push]);

    return (
      <IntlProvider locale={lang} messages={flattenMessages(messages[lang])}>
          <Layout>
              <div className="App">
                  <Switch>
                      <Route exact path="/" render={() => <UserFilesPage idToken={idToken}/>}/>
                      <Route exact path="/login" render={() => <LoginForm isAdmin={false}/>}/>
                      <Route path="/admin" component={Admin}/>
                  </Switch>
              </div>
          </Layout>
      </IntlProvider>
    );
}

export default App;
