import styles from './_styles.module.scss';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import {requestUsers} from "../../redux/actions/adminAction";
import UsersTable from "./UsersTable";
import AccountPageHead from "../AccountPageHead";
import {AvtorTabItem, AvtorTabs, Button} from "../_ui";

const AdminPage = ({idToken}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const userName = useSelector(state => state.admin.user.name);
  const isSuperAdmin = useSelector(state => state.admin.user.isSuper);
  const pageSize = useSelector(state => state.admin.pageSize);
  const currentPage = useSelector(state => state.admin.currentPage);

  const [selectedTable, setSelectedTable] = useState('users');

  useEffect(() => {
    if (idToken) {
      dispatch(requestUsers('users', pageSize, currentPage));
    }
  }, [])

  return (
    <>
      <AccountPageHead
        title={intl.formatMessage({id: 'admin.main.title'})}
        className={styles.account}
        userName={userName}
        userPage
      />
      <div className={`${styles.account_content} mb-4`}>
        <div className={`${styles.account_content_head} ${styles.account_content_head_super}`}>
          <h2 className={`${styles.account_title} ${styles.account_content_title}`}>
            {isSuperAdmin ? intl.formatMessage({id: 'admin.main.subtitle.superAdmin'})
              : intl.formatMessage({id: 'admin.main.subtitle.admin'})}
          </h2>
          <div className={`${styles.account_btn_wrapper} ${styles.account_btn_wrapper_super}`}>
            <Button icon="mail"
                    iconColor="#000"
                    className={styles.account_btn}
                    empty
            >
              {intl.formatMessage({id: 'admin.main.menu.emailBtn'})}
            </Button>

            <NavLink to="/admin/add_user">
              <Button icon="plus"
                      className={styles.account_btn}
                      empty
              >
                {intl.formatMessage({id: 'admin.main.menu.newUserBtn'})}
              </Button>
            </NavLink>
            {isSuperAdmin && <NavLink to="/admin/add_admin">
              <Button icon="plus"
                      className={styles.account_btn}
                      empty
              >
                {intl.formatMessage({id: 'admin.main.menu.newAdminBtn'})}
              </Button>
            </NavLink>}
          </div>
        </div>
        {isSuperAdmin && <AvtorTabs className={styles.table_switcher}>
          <AvtorTabItem active={selectedTable === 'users'}
                        onClick={() => {
                          if (selectedTable !== 'users') {
                            setSelectedTable('users');
                            dispatch(requestUsers('users', pageSize, 1));
                          }
                        }}
          >
            {intl.formatMessage({id: 'admin.main.tableSwitcher.users'})}
          </AvtorTabItem>
          <AvtorTabItem active={selectedTable === 'admins'}
                        onClick={() => {
                          if (selectedTable !== 'admins') {
                            setSelectedTable('admins');
                            dispatch(requestUsers('admins', pageSize, 1));
                          }
                        }}
          >
            {intl.formatMessage({id: 'admin.main.tableSwitcher.admins'})}
          </AvtorTabItem>
        </AvtorTabs>}
        <UsersTable table={selectedTable} pageSize={pageSize}/>
      </div>
    </>
  )
}

export default AdminPage;