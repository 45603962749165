import styles from './_styles.module.scss';
import HR from "../HR";

export const Table = ({ children, className = "", ...props }) => {
    return (
        <>
            <div className={styles.table_wrapper}>
                <table className={className} cellSpacing="1">
                    {children}
                </table>
            </div>
        </>
    )
}

export const TTop = ({ children, className = "", ...props}) => {
    return (
        <>
            <div className={`${styles.table_top} ${className}`}>
                {children}
            </div>
            <HR className={styles.table_hr}/>
        </>
    )
}