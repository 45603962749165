import React from "react";
import styles from "./_styles.module.scss";

export const Tabs = ({ children, className = "", ...props }) => (
  <div
    className={`${styles.tabs}${className ? " " + className : ""}`}
    {...props}
  >
    <div className={styles["tabs-wrapper"]}>{children}</div>
  </div>
);

export const TabItem = ({
  active = false,
  disabled = false,
  children,
  className = "",
  ...props
}) => {
  const classList = `${styles["tab-item"]}${className.length ? " " + className : ""}
  ${active ? " " + styles.active : ""}${disabled ? " " + styles.disabled : ""
  }`;

  const onClickAction = () => {
    if (!disabled && props.onClick && typeof props.onClick === "function") {
      props.onClick();
    }
  };

  return (
    <>
    { disabled ? (<></>) : (
      <> 
        <div className={classList} {...props} onClick={onClickAction}>
          {children}
        </div>
      </>
    )}
    </>
  );
};

// return (
//   <div className={classList} {...props} onClick={onClickAction}>
//     {children}
//   </div>
// );