import {
    SET_FILES_COUNT,
    SET_FILES_CURRENT_PAGE,
    SET_CURRENT_USER_FILES
} from "../types";

const initialState = {
    userFiles: [],
    currentPage: 1,
    pageSize: 10,
    filesCount: 0
}

export const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER_FILES:
            return {
                ...state,
                userFiles: [...action.payload]
            }
        case SET_FILES_COUNT:
            return {
                ...state,
                filesCount: action.payload
            }
        case SET_FILES_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        default:
            return state
    }
}