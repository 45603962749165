import React, { useRef } from "react";
import PropTypes from "prop-types";
import PasswordField from "./Password";
import TextareaField from "./Textarea";
import { Title, Hint, FieldBox } from "../FieldBox";
import styles from "./_styles.module.scss";
import InputMask from 'react-input-mask';
import Icon from "../Icon";


const TextField = ({
  title = "",
  hint = "",
  hintClassName = "",
  type = "text",
  defaultValue = "",
  className = "",
  wrapClassName = "",
  exceptions = "",
  clear = false,
  icon = null,
  button = null, // component <Button></Button>
  ...rest
}) => {
  const inputRef = useRef();

  const checkExceptions = (e) => {
    const anyLetters = (search) => {
      if (search && typeof search === "string") {
        for (let i = 0; i < search.length; i++) {
          if (exceptions.toLowerCase().includes(search[i].toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    };

    if (
      (e.type === "keydown" &&
        e.key &&
        e.key.length === 1 &&
        anyLetters(e.key)) ||
      (e.type === "paste" &&
        e.clipboardData &&
        anyLetters(e.clipboardData.getData("Text")))
    ) {
      e.preventDefault();
      return false;
    }
  };

  const clearAction = () => {
    inputRef.current.value = "";
  };

  return (
    <div
      className={`${button ? styles.hasButton : ""} ${
        rest.disabled ? styles.disabled : ""
      }`}
    >
      <FieldBox className={`${styles["text-field-box"]} ${wrapClassName}`}>
        {rest.maxLength || title ? (
          <Title required={rest.required}>{title}</Title>
        ) : null}
        <label className={className}>
          {type === "password" ? (
            <PasswordField
              onKeyDown={checkExceptions}
              onPaste={checkExceptions}
              {...rest}
            />
          ) : type === "textarea" ? (
            defaultValue.length > 0 ? <TextareaField
              onKeyDown={checkExceptions}
              onPaste={checkExceptions}
              defaultValue={defaultValue}
              {...rest}
            /> : <TextareaField
              onKeyDown={checkExceptions}
              onPaste={checkExceptions}
              {...rest}
            />
          ) : type === "tel" ? (
            <React.Fragment>
              {icon ? (
                <Icon name={icon} className={styles["text-field-icon"]} />
              ) : null}
              <InputMask
                mask="+38 (099)-999-99-99"
                maskChar="_"
                type={type}
                className={`${styles["text-input"]}`}
                defaultValue={defaultValue}
                onKeyDown={checkExceptions}
                onPaste={checkExceptions}
                ref={inputRef}
                {...rest}
              />
              {clear ? <Icon name="times" onClick={clearAction} /> : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {icon ? (
                <Icon name={icon} className={styles["text-field-icon"]} />
              ) : null}
              <input
                type={type}
                className={`${styles["text-input"]}`}
                defaultValue={defaultValue}
                onKeyDown={checkExceptions}
                onPaste={checkExceptions}
                ref={inputRef}
                {...rest}
              />
              {clear ? <Icon name="times" onClick={clearAction} /> : null}
            </React.Fragment>
          )}
        </label>
        <Hint className={hintClassName}>{hint}</Hint>
      </FieldBox>
      {button}
    </div>
  );
};

TextField.propTypes = {
  title: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  exceptions: PropTypes.string,
  clear: PropTypes.bool,
  icon: PropTypes.string,
  rest: PropTypes.any,
};

export default TextField;





// : (
//   <React.Fragment>
//     {icon ? (
//       <Icon name={icon} className={styles["text-field-icon"]} />
//     ) : null}
//     <input
//       type={type}
//       className={`${styles["text-input"]}`}
//       defaultValue={defaultValue}
//       onKeyDown={checkExceptions}
//       onPaste={checkExceptions}
//       ref={inputRef}
//       {...rest}
//     />
//     {clear ? <Icon name="times" onClick={clearAction} /> : null}
//   </React.Fragment>
// )