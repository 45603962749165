export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const required = (value) => (value ? undefined : "required");

export const exception = (exception) => (value) => {
    if(!!value?.length) {
        for(let ch of value) {
            if(exception.toLowerCase().includes(ch)) return "exception";
        }
    }
    return undefined;
}

export const isValidEmail = (value) =>
  !value?.length || /\S+@\S+\.\S+/.test(value) ? undefined : "email";

export const minLength = (min) => (value) =>
  value.length >= min ? undefined : "minLength";

export const maxLength = (max) => (value) =>
   value?.length <= max || !value?.length ? undefined : "maxLength";

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : "minValue";

export const isNumber = (value) =>
    /^\d+$/.test(value) ? undefined : "NaN"
