import {LOCALES} from "../utils/constants";

export const ru = {
    [LOCALES.Russian]: {
        auth: {
            title: 'Авторизация',
            form: {
                userTitle: 'Вход в личный кабинет',
                adminTitle: 'Вход на страницу администратора',
                login: 'Логин',
                emptyLogin: 'Введите логин',
                password: 'Пароль',
                emptyPassword: 'Введите пароль',
                signInBtn: 'Войти'
            }
        },
        user: {
            title: 'Личный кабинет',
            logoutBtn: 'Выйти',
            sentTableTitle: 'Отправленные файлы для АВТОР',
            receivedTableTitle: 'Полученные файлы от АВТОР',
        },
        admin: {
            main: {
                title: 'Административная страница',
                logout: 'Выйти',
                subtitle: {
                    admin: 'Управление пользователями и файлами',
                    superAdmin: 'Управление пользователями и администраторами',
                },
                menu: {
                    emailBtn: 'Управлять E-mail рассылкой',
                    newUserBtn: 'Добавить пользователя',
                    newAdminBtn: 'Добавить администратора',
                },
                tableSwitcher: {
                    users: 'Пользователи',
                    admins: 'Администраторы'
                },
                table: {
                    company: {
                        title: 'Компания',
                        search: 'Поиск по компании',
                        noCompany: 'Название компании не указано'
                    },
                    user: {
                        title: 'Пользователь',
                        search: 'Поиск по пользователю'
                    },
                    admin: {
                        title: 'Администратор'
                    },
                    superAdmin: {
                        title: 'Супер Админ'
                    },
                    email: {
                        title: 'E-mail',
                        search: 'Поиск по E-mail',
                        noEmail: 'E-mail не указано'
                    },
                    password: {
                        title: 'Пароль'
                    },
                    active: {
                        title: 'Активность пользователя',
                        search: {
                            all: 'Все',
                            active: 'Активные',
                            inactive: 'Неактивные'
                        }
                    },
                    sentFiles: {
                        title: 'Отправка файлов',
                        search: {
                            all: 'Все',
                            send: 'Разрешено',
                            noSend: 'Не разрешено'
                        }
                    },
                    date: {
                        title: 'Дата регистрации'
                    },
                    menu: {
                        search: 'Поиск',
                        files: 'Файлы',
                        edit: 'Редактировать',
                        delete: 'Удалить'
                    },
                    deleteModal: {
                        userTitle: 'Удаление пользователя',
                        userBody: 'Вы действительно хотите удалить пользователя ',
                        adminTitle: 'Удаление администратора',
                        adminBody: 'Вы действительно хотите удалть администратора ',
                        cancelBtn: 'Отменить',
                        deleteBtn: 'Удалить',
                    },
                    noUsers: 'Пользователей не найдено',
                    noAdmins: 'Администраторов не найдено'
                }
            },
            userEdit: {
                addTitle: 'Новый пользователь',
                editTitle: 'Редактиврование пользователя',
                form: {
                    company: 'Компания',
                    name: 'Логин пользователя',
                    email: 'E-mail',
                    password: 'Пароль',
                    passwordRepeat: 'Повторите пароль',
                    passwordChangeBtn: 'Изменить пароль',
                    newPassword: 'Новый пароль',
                    active: 'Вход разрешен',
                    instFiles: 'Разрешить отправлять файлы',
                    quota: 'Квота, Мб',
                    createBtn: 'Создать пользователя',
                    saveChangesBtn: 'Сохранить изменения'
                }
            },
            adminEdit: {
                addTitle: 'Новый администратор',
                editTitle: 'Редактирование администратора',
                form: {
                    login: 'Логин администратора',
                    password: 'Пароль',
                    passwordRepeat: 'Повторите пароль',
                    newPassword: 'Новый пароль',
                    super: 'Супер Админ',
                    createBtn: 'Создать администратора'
                }
            },
            userFiles: {
                title: 'Файлы пользователя',
                sentTableTitle: 'Отправленные файлы для пользователя ',
                receivedTableTitle: 'Полученные файлы от пользователя ',
                dragAndDrop: {
                    title: 'Для отправки перетащите файл сюда',
                    or: 'или',
                    selectFileBtn: 'Выбрать файл',
                    maxSize: 'Максимальный размер: ',
                    quota: 'Квота: ',
                    loadBtn: 'Загрузить'
                },
                received: 'Полученные файлы',
                sent: 'Отправленные файлы',
                table: {
                    file: 'Файл',
                    fileFormat: 'Формат файла: ',
                    emptyFileName: 'Имя файла не должно быть пустым',
                    size: 'Размер',
                    date: 'Дата и время загрузки',
                    downloadsCount: 'Общее количество загрузок',
                    description: 'Описание',
                    noDescription: 'Нет описания',
                    deleteBtn: 'Удалить',
                    downloadBtn: 'Загрузить',
                    kbSizeUnit: 'Кб',
                    mbSizeUnit: 'Мб',
                    noSentFiles: 'Нету отправленых файлов',
                    noReceivedFiles: 'Нету полученных файлов'
                },
                deleteModal: {
                    title: 'Удаление файлов',
                    body: 'Вы действительно хотите удалить файлы ',
                    cancel: 'Отменить',
                    delete: 'Удалить'
                }
            },
            backToTable: 'Назад к таблице'
        },
        errors: {
            emptyField: 'Поле не должно быть пустым',
            companyMaxLength: 'Название компании должно быть не больше 100 символов',
            nameMinLength: 'Логин должен быть не меньше 3 символов',
            nameMaxLength: 'Логин должен быть не больше 100 символов',
            nameException: 'Разрешены только буквы (a-z, A-Z), числа (0-9) и символы ("-","_",".")',
            invalidEmail: 'Не коректный E-mail',
            emailException: 'Разрешены только буквы (a-z, A-Z), числа (0-9) и символы ("@","-","_",".")',
            equalEmail: 'Обратите внимание, пользователь с таким E-mail уже существует',
            passwordMinLength: 'Пароль должен быть не меньше 8 символов',
            passwordMaxLength: 'Пароль должен быть не больше 100 символов',
            passwordMatch: 'Пароль не совпадает',
            wrongAuth: 'Неправильный логин или пароль',
            noResponse: 'Нет ответа от сервера',
            sameUserLogin: 'Пользователь с этим логином уже существует',
            sameAdminLogin: 'Администратор с этим логином уже существует',
            quotaException: 'Разрешены только числа',
            quotaLimit: 'Файл не загружен, перевышен лимит квоты',
            quotaMinValue: 'Размер квоты должен быть больше 0',
            maxFileSize: 'Размер файла должен быть не больше 800Мб'
        }
    }
}