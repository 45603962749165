import styles from "../AdminPage/_styles.module.scss";
import {TabItem, Tabs} from "../_ui";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setCurrentPage} from "../../redux/actions/adminAction";

const Pagination = ({count, pageSize, currentPage, onPageChange}) => {
    const dispatch = useDispatch();

    const pagesCount = Math.ceil(count / pageSize);
    const pages = [];
    for(let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    const [arrOfCurrTabs, setArrOfCurrTabs] = useState([]);

    useEffect(() => {
        let tempNumberOfPages;
        if(pagesCount > 4) {
            tempNumberOfPages = [...arrOfCurrTabs];
            let dotsInitial = '...';
            let dotsLeft = '... ';
            let dotsRight = ' ...';

            if (currentPage >= 1 && currentPage <=3) {
                tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pagesCount]
            }

            else if (currentPage === 4) {
                const sliced = pages.slice(0, 5);
                tempNumberOfPages = [...sliced, dotsInitial, pagesCount];
            }

            else if (currentPage > 4 && currentPage < pagesCount - 2) {
                const sliced1 = pages.slice(currentPage - 2, currentPage);
                const sliced2 = pages.slice(currentPage, currentPage + 1);
                tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, ' ...', pagesCount]
            }

            else if (currentPage > pagesCount - 3) {
                const sliced = pages.slice(pagesCount - 4);
                tempNumberOfPages = [1, dotsLeft, ...sliced];
            }

            else if (currentPage === dotsInitial) {
                dispatch(setCurrentPage(arrOfCurrTabs[arrOfCurrTabs.length-3] + 1));
                onPageChange(arrOfCurrTabs[arrOfCurrTabs.length-3] + 1);
            }

            else if (currentPage === dotsLeft) {
                dispatch(setCurrentPage(arrOfCurrTabs[3] - 2));
                onPageChange(arrOfCurrTabs[3] - 2);
            }

            else if (currentPage === dotsRight) {
                dispatch(setCurrentPage(arrOfCurrTabs[3] + 2));
                onPageChange(arrOfCurrTabs[3] + 2);
            }
        } else {
            tempNumberOfPages = [...pages];
        }

        setArrOfCurrTabs(tempNumberOfPages);
    }, [currentPage]);

    return (<Tabs className={styles.th_pagination_tab}>
        {arrOfCurrTabs.map(p => {
            return <TabItem key={p}
                            active={p === currentPage}
                            className={styles.th_pagination_tab_item}
                            onClick={() => {
                                dispatch(setCurrentPage(p));
                                if(typeof p === "number") {
                                    onPageChange(p)
                                }
                            }}
            >{p}</TabItem>
        })}
    </Tabs>)
}

export default Pagination;