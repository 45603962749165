import {LOCALES} from "../utils/constants";

export const en = {
    [LOCALES.English]: {
        auth: {
            title: 'Authorization',
            form: {
                userTitle: 'Login to personal account',
                adminTitle: 'Login to the administrator page',
                login: 'Login',
                emptyLogin: 'Enter login',
                password: 'Password',
                emptyPassword: 'Enter password',
                signInBtn: 'Sign in'
            }
        },
        user: {
            title: 'Personal cabinet',
            logoutBtn: 'Logout',
            sentTableTitle: 'Sent files to AVTOR',
            receivedTableTitle: 'Received files from AVTOR',
        },
        admin: {
            main: {
                title: 'Admin page',
                logout: 'Logout',
                subtitle: {
                    admin: 'Users and file management',
                    superAdmin: 'Users and administrators management',
                },
                menu: {
                    emailBtn: 'Mailing manage',
                    newUserBtn: 'Add user',
                    newAdminBtn: 'Add admin',
                },
                tableSwitcher: {
                    users: 'Users',
                    admins: 'Admins'
                },
                table: {
                    company: {
                        title: 'Company',
                        search: 'Search by company',
                        noCompany: 'Company name not specified'
                    },
                    user: {
                        title: 'User',
                        search: 'Search by user'
                    },
                    admin: {
                      title: 'Admin'
                    },
                    superAdmin: {
                        title: 'Super Admin'
                    },
                    email: {
                        title: 'E-mail',
                        search: 'Search by E-mail',
                        noEmail: 'E-mail not specified'
                    },
                    password: {
                        title: 'Password'
                    },
                    active: {
                        title: 'User activity',
                        search: {
                            all: 'All',
                            active: 'Active',
                            inactive: 'Inactive'
                        }
                    },
                    sentFiles: {
                        title: 'Send files',
                        search: {
                            all: 'All',
                            send: 'Allowed',
                            noSend: 'Not allowed'
                        }
                    },
                    date: {
                        title: 'Registration date'
                    },
                    menu: {
                        search: 'Search',
                        files: 'Files',
                        edit: 'Edit',
                        delete: 'Delete'
                    },
                    deleteModal: {
                        userTitle: 'Delete user',
                        userBody: 'Do you really want to delete the user ',
                        adminTitle: 'Delete admin',
                        adminBody: 'Do you really want to delete the admin ',
                        cancelBtn: 'Cancel',
                        deleteBtn: 'Delete',
                    },
                    noUsers: 'No users found',
                    noAdmins: 'No admins found'
                }
            },
            userEdit: {
                addTitle: 'New user',
                editTitle: 'User editing',
                form: {
                    company: 'Company',
                    name: 'User login',
                    email: 'E-mail',
                    password: 'Password',
                    passwordRepeat: 'Repeat password',
                    passwordChangeBtn: 'Change password',
                    newPassword: 'New password',
                    active: 'Login allowed',
                    instFiles: 'Allow to send files',
                    quota: 'Quota, Mb',
                    createBtn: 'Create user',
                    saveChangesBtn: 'Save changes'
                }
            },
            adminEdit: {
                addTitle: 'New admin',
                editTitle: 'Admin editing',
                form: {
                    login: 'Admin login',
                    password: 'Password',
                    passwordRepeat: 'Repeat password',
                    newPassword: 'New password',
                    super: 'Super Admin',
                    createBtn: 'Create admin'
                }
            },
            userFiles: {
                title: 'User files',
                sentTableTitle: 'Sent files to user ',
                receivedTableTitle: 'Received files from user ',
                dragAndDrop: {
                    title: 'Drag the file here to send',
                    or: 'or',
                    selectFileBtn: 'Select file',
                    maxSize: 'Maximum size: ',
                    quota: 'Quota: ',
                    loadBtn: 'Load'
                },
                received: 'Received files',
                sent: 'Sent files',
                table: {
                    file: 'File',
                    fileFormat: 'File format: ',
                    emptyFileName: 'Filename must not be empty',
                    size: 'Size',
                    date: 'Date and time of load',
                    downloadsCount: 'Total downloads',
                    description: 'Description',
                    noDescription: 'No description',
                    deleteBtn: 'Delete',
                    downloadBtn: 'Download',
                    kbSizeUnit: 'Kb',
                    mbSizeUnit: 'Mb',
                    noSentFiles: 'No files sent',
                    noReceivedFiles: 'No files received'
                },
                deleteModal: {
                    title: 'Delete files',
                    body: 'Do you really want to delete the files ',
                    cancel: 'Cancel',
                    delete: 'Delete'
                }
            },
            backToTable: 'Back to table'
        },
        errors: {
            emptyField: 'Field must not be empty',
            companyMaxLength: 'Company name must be no longer than 100 characters',
            nameMinLength: 'Name must be at least 3 characters long',
            nameMaxLength: 'Login must be no more than 100 characters',
            nameException: 'Allowed only letters (a-z, A-Z), numbers (0-9) and symbols ("-","_",".")',
            invalidEmail: 'Not valid E-mail',
            emailException: 'Allowed only letters (a-z, A-Z), numbers (0-9) and symbols ("@","-","_",".")',
            equalEmail: 'Note that a user with this E-mail already exists',
            passwordMinLength: 'Password must be at least 8 characters long',
            passwordMaxLength: 'Password must be no more than 100 characters',
            passwordMatch: 'Password does not match',
            wrongAuth: 'Incorrect login or password',
            noResponse: 'No response from server',
            sameUserLogin: 'User with this login already exists',
            sameAdminLogin: 'Admin with this login already exists',
            quotaException: 'Allowed only numbers',
            quotaLimit: 'File not uploaded, quota limit exceeded',
            quotaMinValue: 'Quota size must be greater than 0',
            maxFileSize: 'File size should be no more than 800MB'
        }
    }
}