import React from "react";
import PropTypes from "prop-types";
import styles from "./_styles.module.scss";

export const AvtorTabs = ({ children, className = "", ...props }) => (
    <div
        className={`${styles.tabs}${className ? " " + className : ""}`}
        {...props}
    >
        <div className={styles["tabs-wrapper"]}>{children}</div>
    </div>
);

AvtorTabs.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export const AvtorTabItem = ({
                            active = false,
                            disabled = false,
                            newTab = false,
                            children,
                            className = "",
                            ...props
                        }) => {
    const classList = `${styles["tab-item"]}${active ? " " + styles.active : ""}${
        disabled ? " " + styles.disabled : ""
    }${className ? " " + className : ""}`;

    const onClickAction = () => {
        if (!disabled && props.onClick && typeof props.onClick === "function") {
            props.onClick();
        }
    };

    return (
        <div className={classList} {...props} onClick={onClickAction}>
            {children}
        </div>
    )
};

AvtorTabItem.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    to: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.any,
    props: PropTypes.array,
};
