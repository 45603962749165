import { Modal} from "react-bootstrap";
import styles from "./_styles.module.scss";

const AvtorModal = ({show, handleClose, title, body, children}) => {
    return (
        <Modal className={styles.modal} show={show} onHide={handleClose} dialogClassName={styles.dialog}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                {children}
            </Modal.Footer>
        </Modal>
    )
}

export default AvtorModal;