import {
    SET_AUTHENTICATED,
    SET_SELECTED_USER,
    SET_SELECTED_USER_PASSWORD,
    SET_USERS,
    SET_EMAIL_CHECK, SET_USER_DATA, SET_SELECTED_USER_PASSWORD_LOADING, SET_USERS_COUNT, SET_USERS_CURRENT_PAGE
} from "../types";
import {clearErrors, isLoading, setError} from "./uiAction";
import {adminApi, userApi} from "../../utils/api";

export const setAuthenticated = (payload) => ({type: SET_AUTHENTICATED, payload});
export const setUsers = (payload) => ({type: SET_USERS, payload});
export const setUsersCount = (payload) => ({type: SET_USERS_COUNT, payload});
export const setCurrentPage = (payload) => ({type: SET_USERS_CURRENT_PAGE, payload});
export const setSelectedUser = (payload) => ({type: SET_SELECTED_USER, payload});
export const setSelectedUserPassword = (payload) => ({type: SET_SELECTED_USER_PASSWORD, payload});
export const setSelectedUserPasswordLoading = (payload) => ({type: SET_SELECTED_USER_PASSWORD_LOADING, payload});
export const setEmailCheck = (payload) => ({type: SET_EMAIL_CHECK, payload});
export const setUserData = (payload) => ({type: SET_USER_DATA, payload});

export const setAuthorizationData = (status, token, name, id) => {
    const idToken = `Bearer ${token}`;
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('userName', name);
    localStorage.setItem('userStatus', status);
    if(id) {
        localStorage.setItem('userId', id)
    }
    status.includes('admin') ? adminApi.defaults.headers.common['Authorization'] = idToken
        : userApi.defaults.headers.common['Authorization'] = idToken;
}

export const login = (isAdmin, authData, push) => (dispatch) => {
    const requestedApi = (isAdmin ? adminApi : userApi);
    dispatch(isLoading());
    requestedApi.post('/login', authData)
        .then(response => {
            setAuthorizationData(response.data.Status, response.data.Token, response.data.Name, response.data.Id || undefined);
            dispatch(setUserData({
                id: response.data.Id || undefined,
                name: response.data.Name,
                isAdmin: response.data.Status.includes('admin'),
                isSuper: response.data.Status.includes('super')
            }),)
            dispatch(setAuthenticated(true));
            push((isAdmin ? '/admin' : '/'));
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError('response'));
            } else if (error.request) {
                dispatch(setError('request'));
            }
        })
}

export const logout = (isAdmin, push) => (dispatch) => {
    const requestedApi = (isAdmin ? adminApi : userApi);
    requestedApi.delete('/login')
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
        .finally(() => {
            localStorage.removeItem('idToken');
            localStorage.removeItem('userName');
            localStorage.removeItem('userStatus');
            delete adminApi.defaults.headers.common['Authorization'];
            delete userApi.defaults.headers.common['Authorization'];
            dispatch(clearErrors());
            dispatch(setAuthenticated(false));
            dispatch(setUserData({
                id: undefined,
                name: undefined,
                isAdmin: false,
                isSuper: false
            }));
            push('/login');
        })
}

export const requestUsers = (table, limit, pageNumber, companyName, name, email, active) => (dispatch) => {
    dispatch(isLoading());
    adminApi.get(table === 'users' ?
        `/users?CompanyName=${companyName || ''}&Name=${name || ''}&Email=${email || ''}${(active || active === false) ? '&Active=' + active : ''}&Limit=${limit}&Offset=${(pageNumber-1)*limit}`
        :
        `/admin`)
        .then(response => {
            setTimeout(() => {
                dispatch(clearErrors());
            }, 200)
            dispatch(setUsers(response.data));
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
    if(table === 'users') {
        adminApi.get(`/users/count?CompanyName=${companyName || ''}&Name=${name || ''}&Email=${email || ''}${(active || active === false) ? '&Active=' + active : ''}`)
            .then(response => {
                dispatch(setUsersCount(response.data));
                dispatch(setCurrentPage(typeof pageNumber === 'number' ? pageNumber : 1));
                dispatch(clearErrors());
            })
            .catch(error => {
                if(error.response) {
                    dispatch(setError(error.response.data));
                } else if (error.request) {
                    dispatch(setError('No response from server'))
                }
            })
    }
}

export const requestSelectedUser = (userType, userId) => (dispatch) => {
    adminApi.get(userType === 'user' ?
        `/users?UserId=${userId}`
        :
        `/admin?AdminId=${userId}`)
        .then(response => {
            dispatch(setSelectedUser(response.data[0]));
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}

export const createNewUser = (push, userType, user) => (dispatch) => {
    adminApi.post(userType === 'user' ? '/users' : '/admin', user)
        .then(() => {
            dispatch(clearErrors());
            dispatch(setEmailCheck(false));
            dispatch(requestUsers('users', '', '', '', null));
            push('/admin');
        })
        .catch((error) => {
            if (error.response) {
                dispatch(setError(userType === 'user' ? 'sameUserLogin' : 'sameAdminLogin'));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}

export const updateUser = (push, userType, userUpdatedData) => (dispatch) => {
    adminApi.patch(userType === 'user' ? '/users/update' : '/admin', userUpdatedData)
        .then(() => {
            dispatch(clearErrors());
            dispatch(setEmailCheck(false));
            push('/admin');
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(userType === 'user' ? 'sameUserLogin' : 'sameAdminLogin'));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}

export const deleteUser = (table, userId, limit, pageNumber, companyName, name, email, active) => (dispatch) => {
    adminApi.delete(table === 'users' ? '/users' : '/admin', {data: {Id: userId}})
        .then(() => {
            dispatch(clearErrors());
            dispatch(requestUsers(table, companyName, name, email, active))
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}

export const requestUserPassword = (table, userId) => (dispatch) => {
    dispatch(setSelectedUserPasswordLoading({userId, isLoading: true}));
    adminApi.get(table === 'users' ?
        `/users/password?UserId=${userId}`
        :
        `/admin/password?AdminId=${userId}`)
        .then((response) => {
            dispatch(setSelectedUserPassword({userId, password: response.data}));
            dispatch(clearErrors());
        })
        .catch(error => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}

export const checkEmail = (email, id) => (dispatch) => {
    adminApi.get(`users?Email=${email}`)
        .then((response) => {
            if(id) {
                response.data.length ? dispatch(setEmailCheck(response.data[0].ID !== id))
                    :
                    dispatch(setEmailCheck(false));
            } else dispatch(setEmailCheck(!!response.data.length));
            dispatch(clearErrors());
        })
        .catch((error) => {
            if (error.response) {
                dispatch(setError(error.response.data));
            } else if (error.request) {
                dispatch(setError('No response from server'));
            }
        })
}
