import styles from './_styles.module.scss';
import {Form, Field} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../redux/actions/adminAction";
import {useIntl} from "react-intl";
import {required} from "../../utils/validators";
import {useRouter} from "../../utils/hooks";
import {Button, Icon, TextField} from "../_ui";
import {clearErrors} from "../../redux/actions/uiAction";

const LoginForm = ({isAdmin}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {push} = useRouter();

    const error = useSelector((state) => state.ui.error);
    const isLoading = useSelector((state) => state.ui.loading);

    const submitLogin = (e) => {
        const loginData = {
            Login: e.login,
            Password: e.password
        }
        dispatch(login(isAdmin, loginData, push))
    }

    return (
        <div className={styles.form_wrapper}>
            <div className={styles.form}>
                <h3 className={styles.form_title}>{isAdmin ? intl.formatMessage({id: 'auth.form.adminTitle'})
                    :
                    intl.formatMessage({id: 'auth.form.userTitle'})}</h3>
                <Form onSubmit={submitLogin}
                      render={({handleSubmit, pristine, submitting, valid}) => (
                          <form onSubmit={handleSubmit} className={styles.form_fields_wrapper}>
                              <Field name="login"
                                     validate={required}
                                     render={({input, meta}) => {
                                         if (meta.active) {
                                             dispatch(clearErrors());
                                         }
                                         return (
                                             <div className={styles.form_field}>
                                                 <TextField name="login"
                                                            title={intl.formatMessage({id: 'auth.form.login'})}
                                                            exceptions=" "
                                                            tabIndex={1}
                                                            {...input}
                                                 />
                                                 {meta.touched && meta.error && meta.error === 'required' &&
                                                 <span className={styles.error}>
                                                     {intl.formatMessage({id: 'auth.form.emptyLogin'})}
                                                 </span>}
                                             </div>
                                         )}}
                              />
                              <Field name="password"
                                     validate={required}
                                     render={({input, meta}) => {
                                         if (meta.active) {
                                             dispatch(clearErrors());
                                         }
                                         return (
                                             <div className={styles.form_field}>
                                                 <TextField name="password"
                                                            title={intl.formatMessage({id: 'auth.form.password'})}
                                                            type="password"
                                                            exceptions=" "
                                                            className={styles.password_field}
                                                            tabIndex={2}
                                                            {...input}
                                                 />
                                                 {meta.touched && meta.error && meta.error === 'required' &&
                                                 <span className={styles.error}>
                                                     {intl.formatMessage({id: 'auth.form.emptyPassword'})}
                                                 </span>}
                                             </div>
                                         )}}
                              />
                              {error && <span className={`${styles.error} ${styles.error_mt}`}>
                              {error === 'response' ? intl.formatMessage({id: 'errors.wrongAuth'})
                                  : intl.formatMessage({id: 'errors.noResponse'})}
                          </span>}
                              <Button type="submit" className={styles.form_btn}
                                      disabled={submitting || pristine || !valid || isLoading}
                              >
                                  {isLoading ? <Icon name="update" spin/>
                                      :
                                      intl.formatMessage({id: 'auth.form.signInBtn'})}
                              </Button>
                          </form>
                      )}
                />
            </div>
        </div>
    )
}

export default LoginForm;