import React, {useRef} from 'react';
import styles from './_styles.module.scss';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteUser,
  requestUserPassword,
  requestUsers,
  setSelectedUser,
  setSelectedUserPassword
} from "../../redux/actions/adminAction";
import AvtorModal from "../AvtorModal";
import dayjs from "dayjs";
import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import {Button, Icon, Table, TextField} from "../_ui";
import {FieldBox} from "../_ui/FieldBox";
import Pagination from "../common/Pagination";

const UsersTable = ({table, pageSize}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const users = useSelector(state => state.admin.users);
  const selectedUser = useSelector(state => state.admin.selectedUser);
  const isLoading = useSelector(state => state.ui.loading);
  const lang = useSelector(state => state.ui.lang);
  const usersCount = useSelector(state => state.admin.usersCount);
  const currentPage = useSelector(state => state.admin.currentPage)

  const selectActive = useRef(null);

  const activeSelectBoxList = [
    {key: 0, value: intl.formatMessage({id: 'admin.main.table.active.search.all'}), requestValue: null},
    {key: 1, value: intl.formatMessage({id: 'admin.main.table.active.search.active'}), requestValue: true},
    {key: 2, value: intl.formatMessage({id: 'admin.main.table.active.search.inactive'}), requestValue: false}
  ]

  const [activeSelectOpen, setActiveSelectOpen] = useState(false);
  const [activeSelected, setActiveSelected] = useState(activeSelectBoxList[0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [search, setSearch] = useState({
    CompanyName: '',
    Name: '',
    Email: '',
    Active: null
  })

  useEffect(() => {
    setActiveSelected((prev) => activeSelectBoxList[prev.key])
  }, [lang])


  const onSearchChange = e => {
    setSearch((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const handleSelectFieldClick = (option) => {
    setActiveSelected(option);
    setSearch(prev => ({...prev, Active: option.requestValue}));
    dispatch(requestUsers('users', pageSize, 1, search.CompanyName, search.Name, search.Email, option.requestValue));
  }

  const resetSearch = () => {
    if (search.CompanyName !== '' || search.Name !== '' || search.Email !== '' || search.Active !== null) {
      setSearch({
        ...search,
        CompanyName: '',
        Name: '',
        Email: '',
        Active: null
      });
      setActiveSelected(activeSelectBoxList[0]);
      dispatch(requestUsers('users', pageSize, 1, '', '', '', null));
    }
  }

  useEffect(() => {
    const onClickOutside = e => {
      if (!selectActive.current?.contains(e.target)) {
        setActiveSelectOpen(false);
      }
    }
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside)
  }, [])

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setTimeout(() => {
      dispatch(setSelectedUser(null));
    }, 200)
  }
  const handleDeleteUser = () => {
    dispatch(deleteUser(table, selectedUser.ID, search.CompanyName, search.Name, search.Email, search.Active))
    setShowDeleteModal(false);
  }

  const handleSearch = () => dispatch(requestUsers('users', pageSize, 1, search.CompanyName, search.Name, search.Email, search.Active));

  const pagesCount = Math.ceil(usersCount / pageSize);
  const pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  useEffect(() => {
    const handleKeyDownEvent = event => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    }

    document.addEventListener("keydown", handleKeyDownEvent);
    return () => document.removeEventListener("keydown", handleKeyDownEvent);
  }, [search])

  const onValuePaste = (e) => {
    e.preventDefault();
    setSearch((prev) => ({...prev, [e.target.name]: e.clipboardData.getData('Text').trim()}))
  }

  return (
    <>
      {table === 'users' ? (
        <div className={styles.table_wrapper}><Table className={styles.table}>
          <thead>
          <tr>
            <th className={styles.th_company}>
              {intl.formatMessage({id: 'admin.main.table.company.title'})}
            </th>
            <th className={styles.th_user}>
              {intl.formatMessage({id: 'admin.main.table.user.title'})}
            </th>
            <th className={styles.th_email}>
              {intl.formatMessage({id: 'admin.main.table.email.title'})}
            </th>
            <th className={styles.th_password}>
              {intl.formatMessage({id: 'admin.main.table.password.title'})}
            </th>
            <th className={styles.th_checkbox}>
              {intl.formatMessage({id: 'admin.main.table.active.title'})}
            </th>
            <th className={styles.th_checkbox}>
              {intl.formatMessage({id: 'admin.main.table.sentFiles.title'})}
            </th>
            <th className={styles.th_quota}>
              {intl.formatMessage({id: 'admin.userEdit.form.quota'})}
            </th>
            <th className={styles.th_date}>
              {intl.formatMessage({id: 'admin.main.table.date.title'})}
            </th>
            <th className={styles.th_menu}>

            </th>
          </tr>
          <tr>
            <th className={styles.th_search}>
              <TextField name="CompanyName"
                         placeholder={intl.formatMessage({id: 'admin.main.table.company.search'})}
                         value={search.CompanyName}
                         onChange={onSearchChange}
                         wrapClassName={styles.search_field_wrapper}
                         className={styles.search_field}
                         onPaste={onValuePaste}
              />
            </th>
            <th className={styles.th_search}>
              <TextField name="Name"
                         placeholder={intl.formatMessage({id: 'admin.main.table.user.search'})}
                         value={search.Name}
                         onChange={onSearchChange}
                         wrapClassName={styles.search_field_wrapper}
                         className={styles.search_field}
                         onPaste={onValuePaste}
              />
            </th>
            <th className={styles.th_search}>
              <TextField name="Email"
                         placeholder={intl.formatMessage({id: 'admin.main.table.email.search'})}
                         value={search.Email}
                         onChange={onSearchChange}
                         wrapClassName={styles.search_field_wrapper}
                         className={styles.search_field}
                         onPaste={onValuePaste}
              />
            </th>
            <th>

            </th>
            <th className={styles.th_search}>
              <FieldBox className={styles.select_field}>
                <label ref={selectActive}
                       className={`${styles.active_filter} ${activeSelectOpen && styles.active_filter_open}`}
                       onClick={() => setActiveSelectOpen((prev) => !prev)}
                >
                  {activeSelected.value}
                  <Icon name="chevronDown"
                        className={`${styles.active_filter_chevron} ${activeSelectOpen && styles.active_filter_chevron_up}`}/>
                  {activeSelectOpen ? (<div className={styles.active_filter_field_wrapper}>
                    {activeSelectBoxList.map(option => (
                        <div className={styles.active_filter_field}
                             onClick={() => handleSelectFieldClick(option)}
                        >
                          {option.value}
                        </div>
                      )
                    )}
                  </div>) : null}
                </label>
              </FieldBox>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th className={`${styles.th_search} ${styles.th_search_submit}`}>
              <div className={styles.th_search_btn_wrapper}>
                <Button icon="search"
                        className={`${styles.account_btn} ${styles.th_search_btn}`}
                        empty
                        onClick={handleSearch}
                >
                  {intl.formatMessage({id: 'admin.main.table.menu.search'})}
                </Button>
                <Button icon="close"
                        className={`${styles.account_btn} ${styles.th_search_btn}`}
                        empty
                        onClick={resetSearch}
                >
                </Button>
              </div>

            </th>
          </tr>
          </thead>
          <tbody>
          {isLoading ? <tr>
            <td colSpan={'9'} className={styles.files_is_null}>
              <Icon name="spiner" spin/>
            </td>
          </tr> : (
            users.length === 0 ? (<tr>
              <td colSpan='9' className={styles.files_is_null}>
                {intl.formatMessage({id: 'admin.main.table.noUsers'})}
              </td>
            </tr>) : users.map(user => (
              <React.Fragment key={user.ID}>
                <tr>
                  <td className={styles.td_select}>
                                    <span>
                                        {user.CompanyName?.length ? user.CompanyName : intl.formatMessage({id: 'admin.main.table.company.noCompany'})}
                                    </span>
                  </td>
                  <td className={styles.td_select}>
                    <span>{user.Name}</span>
                  </td>
                  <td className={styles.td_select}>
                                    <span>
                                      {user.Email?.length ? user.Email : intl.formatMessage({id: 'admin.main.table.email.noEmail'})}
                                    </span>
                  </td>
                  <td className={`${styles.td_space} ${styles.td_select}`}>
                    <div className={styles.td_password_wrapper}>
                      {user.Password ? <>
                                            <span className={styles.password}>
                                                {user.Password}
                                            </span>
                        <Icon name="eye" className={styles.eye}
                              onClick={() => {
                                dispatch(setSelectedUserPassword({
                                  userId: user.ID,
                                  password: null
                                }))
                              }}/>
                      </> : <>
                                            <span>
                                                &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
                                            </span>
                        {user.PasswordLoading ?
                          <Icon name="spiner" spin className={styles.password_loader}/>
                          :
                          <Icon name="hidden" className={styles.eye}
                                onClick={() => {
                                  dispatch(requestUserPassword(table, user.ID));
                                }}/>
                        }
                      </>
                      }
                    </div>
                  </td>
                  <td className={styles.td_center}>
                    {user.Active ? <Icon name="check"/> :
                      <Icon name="close" className={styles.td_active} color='#000'/>}
                  </td>
                  <td className={styles.td_center}>
                    {user.InstFiles ? <Icon name="check"/> :
                      <Icon name="close" className={styles.td_active} color='#000'/>}
                  </td>
                  <td className={styles.td_center}>
                                    <span>
                                        {(user.MaxQuota / 1024) / 1024}
                                    </span>
                  </td>
                  <td className={`${styles.td_date} ${styles.td_select}`}>
                                    <span>
                                        {dayjs(user.CreatedAt).format('DD.MM.YYYY HH:mm:ss')}
                                    </span>
                  </td>
                  <td className={styles.td_menu}>
                    <NavLink to={'admin/files/' + user.ID}>
                      <Button empty className={styles.td_menu_btn} icon="files">
                        {intl.formatMessage({id: 'admin.main.table.menu.files'})}
                      </Button>
                    </NavLink>
                    <NavLink to={'admin/edit_user/' + user.ID}>
                      <Button empty className={styles.td_menu_btn} icon="edit">
                        {intl.formatMessage({id: 'admin.main.table.menu.edit'})}
                      </Button>
                    </NavLink>
                    <Button empty
                            className={styles.td_menu_btn}
                            icon="trash"
                            onClick={() => {
                              dispatch(setSelectedUser(user));
                              setShowDeleteModal(true)
                            }}
                    >
                      {intl.formatMessage({id: 'admin.main.table.menu.delete'})}
                    </Button>
                  </td>
                </tr>
              </React.Fragment>)
            ))}
          {pages.length > 1 && !isLoading && <tr>
            <td colSpan="9" className={styles.th_pagination}>
              <Pagination count={usersCount}
                          pageSize={pageSize}
                          currentPage={currentPage}
                          onPageChange={(p) => dispatch(requestUsers('users', pageSize, p, search.CompanyName, search.Name, search.Email, search.Active))}
              />
            </td>
          </tr>}
          </tbody>
        </Table>
        </div>
      ) : (
        <div className={`${styles.table_wrapper} ${styles.table_wrapper_admin}`}>
          <Table className={styles.table}>
            <thead>
            <tr className={styles.thead_tr}>
              <th className={styles.th_admin}>
                {intl.formatMessage({id: 'admin.main.table.admin.title'})}
              </th>
              <th className={styles.th_admin_password}>
                {intl.formatMessage({id: 'admin.main.table.password.title'})}
              </th>
              <th className={styles.th_admin_super}>
                {intl.formatMessage({id: 'admin.main.table.superAdmin.title'})}
              </th>
              <th className={styles.th_admin_date}>
                {intl.formatMessage({id: 'admin.main.table.date.title'})}
              </th>
              <th className={styles.th_admin_menu}>

              </th>
            </tr>
            </thead>
            <tbody>
            {isLoading ? <tr>
              <td colSpan={'5'} className={styles.files_is_null}>
                <Icon name="spiner" spin/>
              </td>
            </tr> : users.length === 0 ? (<tr>
              <td colSpan='5' className={styles.files_is_null}>
                {intl.formatMessage({id: 'admin.main.table.noAdmins'})}
              </td>
            </tr>) : users.map(admin => (
              <React.Fragment key={admin.ID}>
                <tr>
                  <td className={`${styles.td_select} ${styles.td_select}`}>
                                        <span>
                                            {admin.Name}
                                        </span>
                  </td>
                  <td className={`${styles.td_space} ${styles.td_select}`}>
                    <div className={styles.td_password_wrapper}>
                      {admin.Password ? <>
                                                <span className={styles.password}>
                                                    {admin.Password}
                                                </span>
                        <Icon name="eye" className={styles.eye}
                              onClick={() => dispatch(setSelectedUserPassword({
                                userId: admin.ID,
                                password: null
                              }))}/>
                      </> : <>
                                                <span>
                                                    &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
                                                </span>
                        <Icon name="hidden" className={styles.eye}
                              onClick={() => dispatch(requestUserPassword(table, admin.ID))}/>
                      </>
                      }
                    </div>
                  </td>
                  <td className={styles.td_center}>
                    {admin.Status ? <Icon name="check"/> :
                      <Icon name="close" className={styles.td_active}/>}
                  </td>
                  <td className={styles.td_select}>
                                        <span>
                                            {dayjs(admin.CreatedAt).format('DD.MM.YYYY HH:mm:ss')}
                                        </span>
                  </td>
                  <td className={styles.td_menu}>
                    <NavLink to={'admin/edit_admin/' + admin.ID}>
                      <Button empty className={styles.td_menu_btn} icon="edit">
                        {intl.formatMessage({id: 'admin.main.table.menu.edit'})}
                      </Button>
                    </NavLink>
                    <Button empty
                            className={styles.td_menu_btn}
                            icon="trash"
                            onClick={() => {
                              dispatch(setSelectedUser(admin));
                              setShowDeleteModal(true)
                            }}
                    >
                      {intl.formatMessage({id: 'admin.main.table.menu.delete'})}
                    </Button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
            </tbody>
          </Table>
        </div>
      )}
      <AvtorModal title={`${table === 'users' ?
        intl.formatMessage({id: 'admin.main.table.deleteModal.userTitle'})
        : intl.formatMessage({id: 'admin.main.table.deleteModal.adminTitle'})}`}
                  body={<div>
                    {table === 'users' ?
                      intl.formatMessage({id: 'admin.main.table.deleteModal.userBody'})
                      : intl.formatMessage({id: 'admin.main.table.deleteModal.adminBody'})}
                    <b>{selectedUser?.Name}</b>
                    ?
                  </div>}
                  show={showDeleteModal}
                  handleClose={handleCloseDeleteModal}
      >
        <Button secondary onClick={handleCloseDeleteModal}>
          {intl.formatMessage({id: 'admin.main.table.deleteModal.cancelBtn'})}
        </Button>
        <Button icon="trash" empty onClick={handleDeleteUser}
                className={styles.modal_delete}>
          {intl.formatMessage({id: 'admin.main.table.deleteModal.deleteBtn'})}
        </Button>
      </AvtorModal>
    </>
  )
}

export default UsersTable;