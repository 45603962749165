import {uk} from "./uk-UA";
import {en} from "./en-US";
import {ru} from "./ru-RU";

const messages = {
    ...en,
    ...ru,
    ...uk
}

export default messages