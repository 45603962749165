import styles from './_styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/actions/adminAction";
import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import HR from "../_ui/HR";
import {useRouter} from "../../utils/hooks";
import {Button, Icon} from "../_ui";

const AccountPageHead = ({title, back, files, userPage, userName, children, className = ""}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { push } = useRouter();

    const isAdmin = useSelector(state => state.admin.user.isAdmin);

    const handleLogout = () => {
        dispatch(logout(isAdmin, push));
    }

    return (
        <>
            <div className={`${styles.account_head} ${userPage && styles.account_head_main} ${className}`}>
                {back && (<>
                    <NavLink to="/admin">
                        <Button empty icon="arrowLeft" className={`${styles.back_to_table} ${styles.with_text}`}>
                            {intl.formatMessage({id: 'admin.backToTable'})}
                        </Button>
                    </NavLink>
                    <NavLink to="/admin">
                        <Button empty className={`${styles.back_to_table} ${styles.without_text}`}>
                            <Icon name="arrowLeft" />
                        </Button>
                    </NavLink>
                </>)}
                {!files && <div className={styles.account_title_wrapper}><h1 className={styles.account_title}>{title}</h1></div>}
                {userPage && <div className={styles.account_head_user_menu}>
                    <p className={styles.account_head_name}>{userName}</p>
                    <Button icon="logout"
                            iconColor="#fa3"
                            className={`${styles.account_btn}`}
                            empty
                            onClick={handleLogout}
                    >
                        {intl.formatMessage({id: 'user.logoutBtn'})}
                    </Button>
                </div>}
                {children}
            </div>
            <HR className={styles.account_head_hr}/>
        </>
    )
}

export default AccountPageHead;