import {
    SET_AUTHENTICATED,
    SET_SELECTED_USER,
    SET_SELECTED_USER_PASSWORD,
    SET_USERS,
    SET_EMAIL_CHECK,
    SET_USER_DATA, SET_SELECTED_USER_PASSWORD_LOADING, SET_USERS_COUNT, SET_USERS_CURRENT_PAGE,
} from "../types";

const initialState = {
    authenticated: false,
    user: {
      id: undefined,
      name: undefined,
      isAdmin: false,
      isSuper: false
    },
    users: [],
    usersCount: 0,
    pageSize: 20,
    currentPage: 1,
    selectedUser: null,
    emailCheck: false
}

export const adminReducer = (state = initialState, {type, payload}) => {
    switch(type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    id: payload?.id,
                    name: payload.name,
                    isAdmin: payload.isAdmin,
                    isSuper: payload.isSuper
                }
            }
        case SET_USERS:
            return {
                ...state,
                users: payload.map(u => {
                    return {
                        ...u,
                        Password: null,
                        PasswordLoading: false
                    }
                })
            }
        case SET_USERS_COUNT:
            return {
                ...state,
                usersCount: payload
            }
        case SET_USERS_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload
            }
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: payload
            }
        case SET_SELECTED_USER_PASSWORD:
            return {
                ...state,
                users: [...state.users].map(u => {
                    if(u.ID === payload.userId) {
                        return {
                            ...u,
                            Password: payload.password,
                            PasswordLoading: false,
                        }
                    }
                    return u;
                })
            }
        case SET_SELECTED_USER_PASSWORD_LOADING:
            return {
                ...state,
                users: [...state.users].map(u => {
                    if(u.ID === payload.userId) {
                        return {
                            ...u,
                            PasswordLoading: payload.isLoading
                        }
                    }
                    return u;
                })
            }
        case SET_EMAIL_CHECK:
            return {
                ...state,
                emailCheck: payload
            }
        default:
            return state;
    }
}