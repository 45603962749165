import styles from "./_styles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, setLanguage} from "../../redux/actions/uiAction";

const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.ui.lang);

    const changeLang = (lang) => {
        if(locale !== lang) {
            dispatch(setLanguage(lang));
            localStorage.setItem("language", lang);
            dispatch(clearErrors());
        }
    }

    return (
        <>
            <div
                className={`${styles["lang-switcher"]} ${styles["lang-switcher_default"]}`}
            >
                <div className={`${styles.lang_tab} ${locale === 'uk' && styles.lang_tab_active}`}
                     onClick={() => changeLang('uk')}
                >UA</div>
                <div className={`${styles.lang_tab} ${locale === 'ru' && styles.lang_tab_active}`}
                     onClick={() => changeLang('ru')}
                >RU</div>
                <div className={`${styles.lang_tab} ${locale === 'en' && styles.lang_tab_active}`}
                     onClick={() => changeLang('en')}
                >EN</div>
            </div>
        </>
    );
};
export default LanguageSwitcher;