import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./_styles.module.scss";
import Icon from "../Icon";

/**
 * @name PasswordField
 * Password field with toggle button
 */
const PasswordField = ({ ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <React.Fragment>
      <input
        className={styles["text-input"]}
        type={showPassword ? "text" : "password"}
        {...rest}
      />
      <Icon
        name={showPassword ? "hidden" : "show"}
        onMouseDown={() => setShowPassword(true)}
        onMouseUp={() => setShowPassword(false)}
        onMouseLeave={() => setShowPassword(false)}
      />
    </React.Fragment>
  );
};

PasswordField.propTypes = {
  rest: PropTypes.any,
};

export default PasswordField;
