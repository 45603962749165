import styles from "./_styles.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFiles,
  loadFile,
  requestUserFiles,
  sendFile,
} from "../../redux/actions/filesAction";
import { setError } from "../../redux/actions/uiAction";
import dayjs from "dayjs";
import { adminApi, userApi } from "../../utils/api";
import AvtorModal from "../AvtorModal";
import { useIntl } from "react-intl";
import { clearErrors } from "../../redux/actions/uiAction";
import { Button, Icon, TabItem, Table, Tabs, TextField, TTop } from "../_ui";
import CheckBox from "../_ui/Checkbox";
import {bytesToMegaBytes} from "../../utils/functions";

const SentFiles = ({ isAdmin, table, currentUser, userId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [drag, setDrag] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [uiFiles, setUiFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [quota, setQuota] = useState({ quota: null, maxQuota: null });
  const [edit, setEdit] = useState({
    fileName: "",
    fileExtension: "",
    description: "",
  });

  const userFiles = useSelector(state => state.files.userFiles);
  const pageSize = useSelector(state => state.files.pageSize);
  const currentPage = useSelector(state => state.files.currentPage);
  const filesCount = useSelector(state => state.files.filesCount);
  const isLoading = useSelector(state => state.ui.loading);
  const error = useSelector(state => state.ui.error);

  const fetchUserData = () => {
    userApi
      .get("/user")
      .then(response => {
        setQuota({
          quota: response.data[0].Quota,
          maxQuota: response.data[0].MaxQuota,
        });
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    isAdmin &&
      currentUser &&
      dispatch(
        requestUserFiles(isAdmin, table, pageSize, currentPage, currentUser.ID)
      );
  }, [currentUser]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [table]);

  useEffect(() => {
    setUiFiles(
      [...userFiles].map(file => ({
        id: file.Uuid,
        fileName: file.Name,
        size: Math.round((file.Size / 1000) * 100) / 100,
        lastModifiedDate: dayjs(file.CreatedAt).format("DD.MM.YYYY"),
        lastModifiedHours: dayjs(file.CreatedAt).format("HH:mm:ss"),
        countOfDownloads: file.Counter,
        url: file.URL,
        description: file.Description,
        edit: false,
      }))
    );
  }, [userFiles]);

  const selectFileHandler = e => {
    e.preventDefault();

    const downloadedFiles =
      e.type === "drop" ? e.dataTransfer.files : e.target.files;

    for (let i = 0; i < downloadedFiles.length; i++) {
      const fileSize = bytesToMegaBytes(downloadedFiles[i].size);
      console.log("fileSize before", fileSize);

      if (fileSize <= 800) {
        setFilesPreview(prev => [
          ...prev,
          { id: prev.length + i + 1, file: downloadedFiles[i] },
        ]);
        dispatch(clearErrors());
      } else {
        dispatch(setError("maxFileSize"));
      }
    }

    setDrag(false);
  };

  const fileUploadHandler = (id, file) => {
    const formData = new FormData();
    formData.append("Name", file.name);
    if (currentUser) {
      formData.append("UserId", currentUser.ID);
    }
    formData.append("File", file);
    formData.append("Description", "");

    dispatch(
      sendFile(
        isAdmin,
        formData,
        pageSize,
        currentPage,
        isAdmin ? currentUser?.ID : userId
      )
    );

    if (!isAdmin) {
      setTimeout(() => {
        fetchUserData();
      }, 5000);
    }

    setFilesPreview([...filesPreview].filter(file => file.id !== id));
  };

  const onCheckBoxChange = file => {
    if (!selectedFiles.includes(file)) {
      setSelectedFiles([...selectedFiles, file]);
    } else {
      setIsCheckAll(false);
      setSelectedFiles([...selectedFiles].filter(f => f.id !== file.id));
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedFiles(uiFiles);
    if (isCheckAll) {
      setSelectedFiles([]);
    }
  };

  const handleLoad = (e, id, fileName) => {
    e.stopPropagation();
    dispatch(loadFile(isAdmin, id, fileName));
  };

  const handleDelete = () => {
    setIsCheckAll(false);
    setSelectedFiles([]);
    dispatch(
      deleteFiles(
        isAdmin,
        selectedFiles,
        pageSize,
        selectedFiles.length === uiFiles.length ? currentPage - 1 : currentPage,
        currentUser?.ID
      )
    );
    setShowDeleteModal(false);
  };

  const pagesCount = Math.ceil(filesCount / pageSize);
  const pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  const handleEdit = (e, file) => {
    e.stopPropagation();

    setEdit(prev => ({
      fileName: file.fileName.split(".")[0],
      fileExtension: file.fileName
        .split(".")
        .filter((el, i) => i !== 0)
        .join("."),
      description: file.description,
    }));

    setUiFiles(prev =>
      prev.map(f => {
        if (f.id === file.id) {
          f.edit = true;
        } else {
          f.edit = false;
        }
        return f;
      })
    );
  };

  const handleSaveEdit = (e, file) => {
    e.stopPropagation();

    const fileUpdate = {
      Uuid: file.id,
      Name: edit.fileName + "." + edit.fileExtension,
      Description: edit.description,
    };

    const requestedApi = isAdmin ? adminApi : userApi;

    if (
      edit.fileName !== file.fileName ||
      edit.description !== file.description
    ) {
      requestedApi
        .patch(`/files/update`, fileUpdate)
        .then(() =>
          dispatch(
            requestUserFiles(
              isAdmin,
              table,
              pageSize,
              currentPage,
              isAdmin ? currentUser?.ID : userId
            )
          )
        );
    } else {
      setUiFiles(prev =>
        prev.map(f => {
          if (f.id === file.id) {
            f.edit = false;
          }
          return f;
        })
      );
    }
  };

  return (
    <>
      <div className={styles.sent_wrapper}>
        {isLoading && (
          <>
            <div className={styles.loader_background}></div>
            <Icon name='spiner' spin className={styles.loader} />
          </>
        )}
        <TTop>
          <div className={styles.table_top_head}>
            {table === "sent" ? (
              <Icon
                name='logout'
                className={`${styles.table_top_head_icon} ${styles.table_top_head_icon_sent}`}
              />
            ) : (
              <Icon name='download' className={styles.table_top_head_icon} />
            )}
            <h3 className={styles.table_top_head_title}>
              {isAdmin
                ? table === "sent"
                  ? `${intl.formatMessage({
                      id: "admin.userFiles.sentTableTitle",
                    })}${currentUser?.Name}`
                  : `${intl.formatMessage({
                      id: "admin.userFiles.receivedTableTitle",
                    })}${currentUser?.Name}`
                : table === "sent"
                ? `${intl.formatMessage({ id: "user.sentTableTitle" })}`
                : `${intl.formatMessage({ id: "user.receivedTableTitle" })}`}
            </h3>
          </div>
        </TTop>
        {table === "sent" && (
          <>
            <TTop className={styles.files_load_wrapper}>
              <div
                className={`${styles.drag_and_drop}${
                  drag ? " " + styles.drag_and_drop_area : ""
                }${
                  error === "maxFileSize" || error === "quotaLimit"
                    ? " " + styles.drag_and_drop_error
                    : ""
                }`}
                onDragStart={e => {
                  e.preventDefault();
                  setDrag(true);
                }}
                onDragLeave={e => {
                  e.preventDefault();
                  setDrag(false);
                }}
                onDragOver={e => {
                  e.preventDefault();
                  setDrag(true);
                }}
                onDrop={e => selectFileHandler(e)}>
                <Icon name='logout' className={styles.drag_and_drop_upload} />
                <p className={styles.drag_and_drop_title}>
                  {intl.formatMessage({
                    id: "admin.userFiles.dragAndDrop.title",
                  })}
                </p>
                <p className={styles.drag_and_drop_or}>
                  {intl.formatMessage({ id: "admin.userFiles.dragAndDrop.or" })}
                </p>
                <Button>
                  <input
                    key={filesPreview.length}
                    multiple
                    type='file'
                    onChange={selectFileHandler}
                  />
                  {intl.formatMessage({
                    id: "admin.userFiles.dragAndDrop.selectFileBtn",
                  })}
                </Button>
                <p className={styles.drag_and_drop_max_length}>
                  {intl.formatMessage({
                    id: "admin.userFiles.dragAndDrop.maxSize",
                  })}
                  800{" "}
                  {intl.formatMessage({
                    id: "admin.userFiles.table.mbSizeUnit",
                  })}
                </p>
                {!isAdmin && (
                  <p className={styles.drag_and_drop_max_length}>
                    {intl.formatMessage({
                      id: "admin.userFiles.dragAndDrop.quota",
                    })}
                    {Math.round(quota.quota / 1024 / 1024)} /{" "}
                    {quota.maxQuota / 1024 / 1024}{" "}
                    {intl.formatMessage({
                      id: "admin.userFiles.table.mbSizeUnit",
                    })}
                  </p>
                )}
                {error === "quotaLimit" && (
                  <p className={styles.error}>
                    {intl.formatMessage({ id: "errors.quotaLimit" })}
                  </p>
                )}
                {error === "maxFileSize" && (
                  <p className={styles.error}>
                    {intl.formatMessage({ id: "errors.maxFileSize" })}
                  </p>
                )}
              </div>
              {!!filesPreview?.length && (
                <>
                  <div className={styles.files_preview_wrapper}>
                    {filesPreview?.map(fp => (
                      <div className={styles.files_preview}>
                        <span className={styles.files_preview_text}>
                          {fp?.file?.name}
                        </span>
                        <span className={styles.files_preview_text}>
                          {fp.file.size > 1048576
                            ? bytesToMegaBytes(fp?.file?.size) +
                              " " +
                              intl.formatMessage({
                                id: "admin.userFiles.table.mbSizeUnit",
                              })
                            : Math.round((fp?.file?.size / 1000) * 100) / 100 +
                              " " +
                              intl.formatMessage({
                                id: "admin.userFiles.table.kbSizeUnit",
                              })}
                        </span>
                        <Button
                          className={styles.load_files_btn}
                          onClick={() => fileUploadHandler(fp?.id, fp?.file)}>
                          {intl.formatMessage({
                            id: "admin.userFiles.dragAndDrop.loadBtn",
                          })}
                        </Button>
                        <Button
                          secondary
                          className={styles.delete_preview_file_btn}
                          onClick={() => {
                            setFilesPreview(
                              [...filesPreview].filter(
                                file => file?.id !== fp?.id
                              )
                            );
                          }}>
                          <Icon name='trash' />
                        </Button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </TTop>
          </>
        )}
        <div className={styles.table_wrapper}>
          <Table>
            <thead>
              <tr>
                {table === "sent" && (
                  <th className={styles.th_select_all}>
                    <CheckBox
                      className={styles.th_checkbox}
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  </th>
                )}
                <th className={styles.th_file}>
                  {intl.formatMessage({ id: "admin.userFiles.table.file" })}
                </th>
                <th className={styles.th_size}>
                  {intl.formatMessage({ id: "admin.userFiles.table.size" })}
                </th>
                <th className={styles.th_date}>
                  {intl.formatMessage({ id: "admin.userFiles.table.date" })}
                </th>
                {table === "sent" && isAdmin && (
                  <th className={styles.th_count}>
                    {intl.formatMessage({
                      id: "admin.userFiles.table.downloadsCount",
                    })}
                  </th>
                )}
                <th className={styles.th_description}>
                  {intl.formatMessage({
                    id: "admin.userFiles.table.description",
                  })}
                </th>
                <th className={styles.th_menu}>
                  {table === "sent" && (
                    <div className={styles.delete_btn_wrapper}>
                      <Button
                        secondary
                        disabled={!selectedFiles.length}
                        icon='trash'
                        className={styles.with_text}
                        onClick={() => setShowDeleteModal(true)}>
                        {intl.formatMessage({
                          id: "admin.userFiles.table.deleteBtn",
                        })}
                      </Button>
                      <Button
                        secondary
                        className={styles.without_text}
                        onClick={() => setShowDeleteModal(true)}>
                        <Icon name='trash' />
                      </Button>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {uiFiles.length === 0 ? (
                <tr>
                  <td colSpan='7' className={styles.files_is_null}>
                    {table === "sent"
                      ? intl.formatMessage({
                          id: "admin.userFiles.table.noSentFiles",
                        })
                      : intl.formatMessage({
                          id: "admin.userFiles.table.noReceivedFiles",
                        })}
                  </td>
                </tr>
              ) : (
                uiFiles.map(f => (
                  <tr
                    key={f.id}
                    className={styles.tr_file}
                    style={table === "received" ? { cursor: "initial" } : null}
                    onClick={e => {
                      e.preventDefault();
                      table === "sent" && onCheckBoxChange(f);
                    }}>
                    {table === "sent" && (
                      <td>
                        <CheckBox
                          onChange={() => onCheckBoxChange(f)}
                          checked={selectedFiles.includes(f)}
                        />
                      </td>
                    )}
                    <td
                      className={`${styles.td_edit} ${
                        f.edit && styles.td_edit_open
                      }`}>
                      {f.edit ? (
                        <>
                          <TextField
                            className={`${styles.description_field} ${
                              !edit.fileName.length && "requiring"
                            }`}
                            value={edit.fileName}
                            hint={
                              !edit.fileName.length
                                ? intl.formatMessage({
                                    id: "admin.userFiles.table.emptyFileName",
                                  })
                                : intl.formatMessage({
                                    id: "admin.userFiles.table.fileFormat",
                                  }) + edit.fileExtension
                            }
                            hintClassName={
                              !edit.fileName.length && styles.error
                            }
                            onChange={e =>
                              setEdit(prev => ({
                                ...prev,
                                fileName: e.target.value,
                              }))
                            }
                            onClick={e => e.stopPropagation()}
                          />
                        </>
                      ) : (
                        <>
                          <span className={styles.description}>
                            {f.fileName}
                          </span>
                        </>
                      )}
                    </td>
                    <td>
                      {f.size > 1024
                        ? Math.round((f.size / 1000) * 100) / 100 +
                          " " +
                          intl.formatMessage({
                            id: "admin.userFiles.table.mbSizeUnit",
                          })
                        : f.size +
                          " " +
                          intl.formatMessage({
                            id: "admin.userFiles.table.kbSizeUnit",
                          })}
                    </td>
                    <td>
                      <div className={styles.file_change_time}>
                        {f.lastModifiedDate}
                        <span className={styles.file_change_time_hours}>
                          {f.lastModifiedHours}
                        </span>
                      </div>
                    </td>
                    {table === "sent" && isAdmin && (
                      <td className={styles.td_count}>{f.countOfDownloads}</td>
                    )}
                    <td className={styles.td_edit}>
                      {f.edit ? (
                        <>
                          <TextField
                            className={styles.description_field}
                            value={edit.description}
                            onChange={e =>
                              setEdit(prev => ({
                                ...prev,
                                description: e.target.value,
                              }))
                            }
                            onClick={e => e.stopPropagation()}
                          />
                        </>
                      ) : (
                        <>
                          <span>
                            {f.description === "" || !f.description
                              ? intl.formatMessage({
                                  id: "admin.userFiles.table.noDescription",
                                })
                              : f.description}
                          </span>
                        </>
                      )}
                    </td>
                    <td className={styles.td_menu}>
                      {table === "sent" ? (
                        <div className={styles.btns_wrapper}>
                          {f.edit ? (
                            <Button
                              disabled={edit.fileName === ""}
                              className={styles.menu_edit}
                              onClick={e => handleSaveEdit(e, f)}>
                              <Icon name='check' />
                            </Button>
                          ) : (
                            <Button
                              className={styles.menu_edit}
                              onClick={e => {
                                handleEdit(e, f);
                              }}>
                              <Icon name='edit' />
                            </Button>
                          )}
                          <Button
                            icon='download'
                            className={styles.download}
                            onClick={e => handleLoad(e, f.id, f.fileName)}>
                            {intl.formatMessage({
                              id: "admin.userFiles.table.downloadBtn",
                            })}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          icon='download'
                          className={styles.download}
                          onClick={e => handleLoad(e, f.id, f.fileName)}>
                          {intl.formatMessage({
                            id: "admin.userFiles.table.downloadBtn",
                          })}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              )}
              {pages.length > 1 && (
                <tr>
                  <td colSpan='7'>
                    <Tabs className={styles.th_pagination_tab}>
                      {pages.map(p => {
                        return (
                          <TabItem
                            active={p === currentPage}
                            style={{ borderRadius: "10px" }}
                            className={styles.th_pagination_tab_item}
                            onClick={() => {
                              p !== currentPage &&
                                dispatch(
                                  requestUserFiles(
                                    isAdmin,
                                    table,
                                    pageSize,
                                    p,
                                    isAdmin ? currentUser.ID : userId
                                  )
                                );
                            }}>
                            {p}
                          </TabItem>
                        );
                      })}
                    </Tabs>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <AvtorModal
        title={intl.formatMessage({ id: "admin.userFiles.deleteModal.title" })}
        body={
          <div>
            {intl.formatMessage({ id: "admin.userFiles.deleteModal.body" })}
            <b>
              {selectedFiles.map((f, i) =>
                i === selectedFiles.length - 1 ? f.fileName : f.fileName + ", "
              )}
            </b>
          </div>
        }
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}>
        <Button secondary onClick={() => setShowDeleteModal(false)}>
          {intl.formatMessage({ id: "admin.userFiles.deleteModal.cancel" })}
        </Button>
        <Button
          icon='trash'
          empty
          onClick={handleDelete}
          className={styles.modal_delete}>
          {intl.formatMessage({ id: "admin.userFiles.deleteModal.delete" })}
        </Button>
      </AvtorModal>
    </>
  );
};

export default SentFiles;
