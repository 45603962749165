import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import AccountPageHead from "../../components/AccountPageHead";
import UserForm from "../../components/AccountForms/UserForm";

const EditAdmin = () => {
    const isAdmin = useSelector(state => state.admin.user.isAdmin);
    const isSuperAdmin = useSelector(state => state.admin.user.isSuper);
    const intl = useIntl();

    return (
        <>
            {
                isAdmin && isSuperAdmin && (<>
                    <AccountPageHead title={intl.formatMessage({id: 'admin.adminEdit.editTitle'})} back/>
                    <UserForm editAdmin/>
                </>)
            }
        </>
    )
}

export default EditAdmin;