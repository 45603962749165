import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./_styles.module.scss";

/**
 * @name TextareaField
 * Textarea field with auto height resize
 */
const TextareaField = ({ ...rest }) => (
  <TextareaAutosize
    autoFocus={rest.autoFocus || false}
    className={`${styles["text-input"]} ${styles.textarea}`}
    {...rest}
  />
);

TextareaField.propTypes = {
  rest: PropTypes.any,
};

export default TextareaField;
